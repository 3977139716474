.modeSwitch {
    padding: 0 0.7em;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 0.5em;

    & label {
        cursor: pointer;
    }
}
