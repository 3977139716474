.searchPopup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    gap: 1.5em;
    overflow: auto;
    max-height: 80vh;
    padding: 10px;
}

.tag {
    padding-inline-start: 8px;
    padding-inline-end: 4px;
    background: rgba(0, 0, 0, 0.06);
    font-size: 14px;
}
