/* Some hackery for this specific component to allow us to put a Table in a Card component without messing up the borders */
.ant-table {
    .ant-table-header.ant-table-header {
        border-radius: 0;
        border-top: 1px solid #f0f0f0;


        & table {
            border-radius: 0;
            
            & > thead > tr > th:first-child {
                border-radius: 0;
            }
        }
    }
}
