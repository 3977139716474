/*
    THIS FILES WAS GENERATED AUTOMATICALLY BY THE FIGMA PLUGIN
    DO NOT EDIT THIS FILE.
*/
:root,
:root.light {
    /* -- PRIMITIVES -- */
    --color-dark-azure-100: #090d0f;
    --color-dark-azure-1000: #88c4e2;
    --color-dark-azure-200: #0c1214;
    --color-dark-azure-300: #151f24;
    --color-dark-azure-400: #1c292f;
    --color-dark-azure-500: #23333b;
    --color-dark-azure-600: #304650;
    --color-dark-azure-700: #3e5967;
    --color-dark-azure-800: #4d7081;
    --color-dark-azure-900: #618ba1;
    --color-dark-base-black: #000000;
    --color-dark-base-inverse: #ffffff;
    --color-dark-blue-100: #000d16;
    --color-dark-blue-1000: #95c1df;
    --color-dark-blue-200: #00121e;
    --color-dark-blue-300: #001f34;
    --color-dark-blue-400: #002a46;
    --color-dark-blue-500: #003355;
    --color-dark-blue-600: #004675;
    --color-dark-blue-700: #005894;
    --color-dark-blue-800: #086fb5;
    --color-dark-blue-900: #3a8cc4;
    --color-dark-charcoal-100: #0c0c0c;
    --color-dark-charcoal-1000: #bcbcbc;
    --color-dark-charcoal-200: #111111;
    --color-dark-charcoal-300: #1d1d1d;
    --color-dark-charcoal-400: #272727;
    --color-dark-charcoal-500: #313131;
    --color-dark-charcoal-600: #434343;
    --color-dark-charcoal-700: #565555;
    --color-dark-charcoal-800: #6c6b6b;
    --color-dark-charcoal-900: #868585;
    --color-dark-green-100: #001106;
    --color-dark-green-1000: #92c8a5;
    --color-dark-green-200: #001608;
    --color-dark-green-300: #00230c;
    --color-dark-green-400: #002f11;
    --color-dark-green-500: #003914;
    --color-dark-green-600: #004e1c;
    --color-dark-green-700: #006423;
    --color-dark-green-800: #007c2c;
    --color-dark-green-900: #2f9854;
    --color-dark-grey-100: #0c0c0c;
    --color-dark-grey-1000: #bbbbbb;
    --color-dark-grey-200: #121212;
    --color-dark-grey-300: #1e1e1e;
    --color-dark-grey-400: #282828;
    --color-dark-grey-500: #313131;
    --color-dark-grey-600: #434343;
    --color-dark-grey-700: #555555;
    --color-dark-grey-800: #6b6b6b;
    --color-dark-grey-900: #858585;
    --color-dark-lemon-100: #0d0c03;
    --color-dark-lemon-1000: #cebe29;
    --color-dark-lemon-200: #141204;
    --color-dark-lemon-300: #211f07;
    --color-dark-lemon-400: #2b2809;
    --color-dark-lemon-500: #35310b;
    --color-dark-lemon-600: #4a440f;
    --color-dark-lemon-700: #5e5713;
    --color-dark-lemon-800: #766d18;
    --color-dark-lemon-900: #93871d;
    --color-dark-lila-100: #0c0c15;
    --color-dark-lila-1000: #b5b5ff;
    --color-dark-lila-200: #11111c;
    --color-dark-lila-300: #1c1c2f;
    --color-dark-lila-400: #25253e;
    --color-dark-lila-500: #2e2e4c;
    --color-dark-lila-600: #3f3f6a;
    --color-dark-lila-700: #505086;
    --color-dark-lila-800: #6464a7;
    --color-dark-lila-900: #7d7dd1;
    --color-dark-opacity-dark-200: #000000cc;
    --color-dark-opacity-dark-400: #00000066;
    --color-dark-opacity-light-100: #ffffff1a;
    --color-dark-opacity-light-200: #ffffff33;
    --color-dark-opacity-light-300: #ffffff4d;
    --color-dark-opacity-light-400: #ffffff66;
    --color-dark-orange-100: #120b03;
    --color-dark-orange-1000: #ffa94d;
    --color-dark-orange-200: #1b1004;
    --color-dark-orange-300: #2c1a06;
    --color-dark-orange-400: #3a2208;
    --color-dark-orange-500: #472a0a;
    --color-dark-orange-600: #623a0e;
    --color-dark-orange-700: #7d4912;
    --color-dark-orange-800: #9c5b17;
    --color-dark-orange-900: #c3721c;
    --color-dark-peach-100: #0e0c08;
    --color-dark-peach-1000: #d5b880;
    --color-dark-peach-200: #15120c;
    --color-dark-peach-300: #211d14;
    --color-dark-peach-400: #2d271b;
    --color-dark-peach-500: #373021;
    --color-dark-peach-600: #4c422e;
    --color-dark-peach-700: #61543a;
    --color-dark-peach-800: #7a6949;
    --color-dark-peach-900: #98835b;
    --color-dark-pink-100: #110a0f;
    --color-dark-pink-1000: #ff9bde;
    --color-dark-pink-200: #170e14;
    --color-dark-pink-300: #2a1925;
    --color-dark-pink-400: #372130;
    --color-dark-pink-500: #43283a;
    --color-dark-pink-600: #5c3750;
    --color-dark-pink-700: #754666;
    --color-dark-pink-800: #935880;
    --color-dark-pink-900: #b76e9e;
    --color-dark-purple-100: #0a0b16;
    --color-dark-purple-1000: #b5badf;
    --color-dark-purple-200: #0e101f;
    --color-dark-purple-300: #181b35;
    --color-dark-purple-400: #212548;
    --color-dark-purple-500: #282d58;
    --color-dark-purple-600: #373e78;
    --color-dark-purple-700: #464f99;
    --color-dark-purple-800: #5964b8;
    --color-dark-purple-900: #7881c6;
    --color-dark-red-100: #230005;
    --color-dark-red-1000: #f1a8b2;
    --color-dark-red-200: #2c0006;
    --color-dark-red-300: #46000a;
    --color-dark-red-400: #58000c;
    --color-dark-red-500: #69000e;
    --color-dark-red-600: #8c0013;
    --color-dark-red-700: #af0018;
    --color-dark-red-800: #d70723;
    --color-dark-red-900: #e35467;
    --color-dark-turquoise-100: #080d0c;
    --color-dark-turquoise-1000: #7acbb0;
    --color-dark-turquoise-200: #0b1210;
    --color-dark-turquoise-300: #14211c;
    --color-dark-turquoise-400: #1a2b25;
    --color-dark-turquoise-500: #20352e;
    --color-dark-turquoise-600: #2c493f;
    --color-dark-turquoise-700: #385d51;
    --color-dark-turquoise-800: #457464;
    --color-dark-turquoise-900: #57917d;
    --color-dark-violet-100: #100b12;
    --color-dark-violet-1000: #e1a5ff;
    --color-dark-violet-200: #150e18;
    --color-dark-violet-300: #24192a;
    --color-dark-violet-400: #312239;
    --color-dark-violet-500: #3c2945;
    --color-dark-violet-600: #53395f;
    --color-dark-violet-700: #6a497a;
    --color-dark-violet-800: #845b98;
    --color-dark-violet-900: #a572be;
    --color-dark-yellow-100: #0e0c00;
    --color-dark-yellow-1000: #d8bb00;
    --color-dark-yellow-200: #151200;
    --color-dark-yellow-300: #231e00;
    --color-dark-yellow-400: #2e2800;
    --color-dark-yellow-500: #393100;
    --color-dark-yellow-600: #4e4300;
    --color-dark-yellow-700: #635500;
    --color-dark-yellow-800: #7b6b00;
    --color-dark-yellow-900: #9b8500;
    --color-light-azure-100: #e5f6ff;
    --color-light-azure-200: #cceeff;
    --color-light-azure-300: #b2e5ff;
    --color-light-azure-400: #99ddff;
    --color-light-base-black: #000000;
    --color-light-base-inverse: #ffffff;
    --color-light-blue-100: #f3f8fb;
    --color-light-blue-1000: #003e69;
    --color-light-blue-200: #eaf3f9;
    --color-light-blue-300: #d7e8f3;
    --color-light-blue-400: #c4dded;
    --color-light-blue-500: #afd1e7;
    --color-light-blue-600: #85b8db;
    --color-light-blue-700: #5ca0ce;
    --color-light-blue-800: #2f86c1;
    --color-light-blue-900: #0069b0;
    --color-light-charcoal-100: #fafafa;
    --color-light-charcoal-200: #f2f2f2;
    --color-light-charcoal-300: #e6e6e6;
    --color-light-charcoal-400: #d9d9d9;
    --color-light-charcoal-700: #0000004d;
    --color-light-green-100: #f3f9f5;
    --color-light-green-1000: #004619;
    --color-light-green-200: #ebf5ef;
    --color-light-green-300: #d4eadc;
    --color-light-green-400: #c2e1cd;
    --color-light-green-500: #add6bb;
    --color-light-green-600: #80c097;
    --color-light-green-700: #54aa72;
    --color-light-green-800: #25924c;
    --color-light-green-900: #00762a;
    --color-light-grey-100: #f7f7f7;
    --color-light-grey-1000: #3c3c3c;
    --color-light-grey-200: #f3f3f3;
    --color-light-grey-300: #e5e5e5;
    --color-light-grey-400: #d9d9d9;
    --color-light-grey-500: #cccccc;
    --color-light-grey-600: #b2b2b2;
    --color-light-grey-700: #999999;
    --color-light-grey-800: #808080;
    --color-light-grey-900: #666666;
    --color-light-lemon-100: #fffce5;
    --color-light-lemon-200: #fffacc;
    --color-light-lemon-300: #fff7b2;
    --color-light-lemon-400: #ffeb33;
    --color-light-lila-100: #e5e5ff;
    --color-light-lila-200: #ccccff;
    --color-light-lila-300: #b2b2ff;
    --color-light-lila-400: #9999ff;
    --color-light-opacity-black-100: #0000001a;
    --color-light-opacity-black-200: #00000033;
    --color-light-opacity-black-300: #0000004d;
    --color-light-opacity-black-400: #00000066;
    --color-light-opacity-white-300: #ffffffcc;
    --color-light-orange-100: #fff5eb;
    --color-light-orange-1000: #58330d;
    --color-light-orange-200: #fff1e1;
    --color-light-orange-300: #ffe0bf;
    --color-light-orange-400: #ffd09e;
    --color-light-orange-500: #ffc07c;
    --color-light-orange-600: #ff9a2c;
    --color-light-orange-700: #df8320;
    --color-light-orange-800: #ba6e1b;
    --color-light-orange-900: #955716;
    --color-light-peach-100: #fff6e5;
    --color-light-peach-200: #ffeecc;
    --color-light-peach-300: #ffe5b2;
    --color-light-peach-400: #ffdd99;
    --color-light-pink-100: #ffe5f6;
    --color-light-pink-200: #ffccee;
    --color-light-pink-300: #ffb2e5;
    --color-light-pink-400: #ff99dd;
    --color-light-purple-100: #f5f6fb;
    --color-light-purple-1000: #31386b;
    --color-light-purple-200: #f1f2f9;
    --color-light-purple-300: #e3e5f3;
    --color-light-purple-400: #d5d8ed;
    --color-light-purple-500: #c7cbe7;
    --color-light-purple-600: #a9afdb;
    --color-light-purple-700: #8e95cf;
    --color-light-purple-800: #727bc3;
    --color-light-purple-900: #545eb6;
    --color-light-red-100: #fdf5f6;
    --color-light-red-1000: #7f0011;
    --color-light-red-200: #fceff1;
    --color-light-red-300: #fadfe2;
    --color-light-red-400: #f7cfd4;
    --color-light-red-500: #f5bfc6;
    --color-light-red-600: #ef9ba7;
    --color-light-red-700: #e97686;
    --color-light-red-800: #e2485c;
    --color-light-red-900: #ce001c;
    --color-light-turquoise-100: #e5fff6;
    --color-light-turquoise-200: #ccffee;
    --color-light-turquoise-300: #b2ffe5;
    --color-light-turquoise-400: #99ffdd;
    --color-light-violet-100: #f6e5ff;
    --color-light-violet-200: #eeccff;
    --color-light-violet-300: #e5b2ff;
    --color-light-violet-400: #dd99ff;
    --color-light-yellow-100: #fff8cc;
    --color-light-yellow-1000: #453c00;
    --color-light-yellow-200: #fff4b2;
    --color-light-yellow-300: #ffe64b;
    --color-light-yellow-400: #fad800;
    --color-light-yellow-500: #eccb00;
    --color-light-yellow-600: #cdb100;
    --color-light-yellow-700: #b19800;
    --color-light-yellow-800: #947f00;
    --color-light-yellow-900: #766500;
    --size-border-bold: 2px;
    --size-border-none: 0px;
    --size-border-normal: 1px;
    --size-border-xbold: 4px;
    --size-space--1_5x: -6px;
    --size-space-0_25x: 1px;
    --size-space-0_5x: 2px;
    --size-space-0x: 0px;
    --size-space-10x: 40px;
    --size-space-12x: 48px;
    --size-space-14x: 56px;
    --size-space-16x: 64px;
    --size-space-18x: 72px;
    --size-space-1_25x: 5px;
    --size-space-1_5x: 6px;
    --size-space-1x: 4px;
    --size-space-24x: 96px;
    --size-space-2_5x: 10px;
    --size-space-2x: 8px;
    --size-space-32x: 128px;
    --size-space-3x: 12px;
    --size-space-4x: 16px;
    --size-space-5x: 20px;
    --size-space-6x: 24px;
    --size-space-7x: 28px;
    --size-space-8x: 32px;
    --size-space-9x: 36px;
    --size-space-pill: 999px;

    /* Mode: Light */

    /* color-scheme property is used to make sure browser UI is in light mode */
    color-scheme: light;

    /* -- DESIGN TOKENS -- */
    --color-border-base-container: var(--color-light-base-inverse);
    --color-border-base-container-alt: var(--color-light-base-inverse);
    --color-border-base-interactive11-popup-container: var(--color-light-base-inverse);
    --color-border-brand-active: var(--color-light-purple-900);
    --color-border-brand-hovered: var(--color-light-purple-800);
    --color-border-brand-interactive10-active: var(--color-light-purple-900);
    --color-border-brand-interactive10-active-default: var(--color-light-purple-900);
    --color-border-brand-interactive11-active: var(--color-light-purple-900);
    --color-border-brand-interactive3-light: var(--color-light-purple-300);
    --color-border-brand-interactive4b-active: var(--color-light-purple-900);
    --color-border-brand-interactive4b-disabled: var(--color-light-purple-500);
    --color-border-brand-interactive4b-hovered: var(--color-light-purple-700);
    --color-border-brand-interactive4b-pressed: var(--color-light-purple-1000);
    --color-border-brand-interactive9-active: var(--color-light-purple-900);
    --color-border-brand-light: var(--color-light-purple-300);
    --color-border-brand-pressed: var(--color-light-purple-1000);
    --color-border-feedback-active: var(--color-light-purple-900);
    --color-border-feedback-error-darkest: var(--color-light-red-900);
    --color-border-feedback-error-light: var(--color-light-red-300);
    --color-border-feedback-information-darkest: var(--color-light-blue-900);
    --color-border-feedback-information-light: var(--color-light-blue-300);
    --color-border-feedback-interactive10-error-darkest: var(--color-light-red-900);
    --color-border-feedback-interactive11-error-darkest: var(--color-light-red-900);
    --color-border-feedback-success-darkest: var(--color-light-green-900);
    --color-border-feedback-success-light: var(--color-light-green-300);
    --color-border-feedback-warning-darkest: var(--color-light-orange-900);
    --color-border-feedback-warning-light: var(--color-light-orange-300);
    --color-border-feedback-warning-middark: var(--color-light-orange-600);
    --color-border-neutral-container-darkest: var(--color-light-grey-900);
    --color-border-neutral-container-light: var(--color-light-grey-300);
    --color-border-neutral-container-lighter: var(--color-light-grey-200);
    --color-border-neutral-container-mid: var(--color-light-grey-500);
    --color-border-neutral-container-middark: var(--color-light-grey-600);
    --color-border-neutral-divider-mid: var(--color-light-grey-500);
    --color-border-neutral-interactive10-default: var(--color-light-grey-500);
    --color-border-neutral-interactive10-disabled: var(--color-light-grey-500);
    --color-border-neutral-interactive10-disabled-prefilled: var(--color-light-grey-500);
    --color-border-neutral-interactive10-filled: var(--color-light-grey-500);
    --color-border-neutral-interactive11-default: var(--color-light-grey-500);
    --color-border-neutral-interactive11-disabled: var(--color-light-grey-500);
    --color-border-neutral-interactive11-filled: var(--color-light-grey-500);
    --color-border-neutral-interactive2-disabled-selected: var(--color-light-grey-300);
    --color-border-neutral-interactive2-disabled-unselected: var(--color-light-grey-300);
    --color-border-neutral-interactive8-active: var(--color-light-grey-300);
    --color-border-neutral-interactive8-default: var(--color-light-grey-300);
    --color-border-neutral-interactive8-disabled: var(--color-light-grey-300);
    --color-border-neutral-interactive8-hovered: var(--color-light-grey-300);
    --color-border-neutral-interactive8-pressed: var(--color-light-grey-300);
    --color-border-neutral-interactive8-selected: var(--color-light-grey-300);
    --color-border-primary-brand-darkest: var(--color-light-purple-900);
    --color-border-primary-brand-interactive1-disabled: var(--color-light-purple-400);
    --color-border-primary-brand-interactive1-hovered: var(--color-light-purple-700);
    --color-border-primary-brand-interactive1-pressed: var(--color-light-purple-1000);
    --color-border-primary-brand-interactive2-enabled: var(--color-light-purple-900);
    --color-border-secondary-brand-interactive1-disabled: var(--color-light-purple-400);
    --color-border-secondary-brand-interactive1-enabled: var(--color-light-purple-900);
    --color-border-secondary-brand-interactive1-hovered: var(--color-light-purple-700);
    --color-border-secondary-brand-interactive1-pressed: var(--color-light-purple-1000);
    --color-border-secondary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-border-secondary-destructive-interactive1-disabled: var(--color-light-red-300);
    --color-border-secondary-destructive-interactive1-enabled: var(--color-light-red-900);
    --color-border-secondary-destructive-interactive1-hovered: var(--color-light-red-700);
    --color-border-secondary-destructive-interactive1-pressed: var(--color-light-red-1000);
    --color-border-secondary-destructive-interactive1-progress: var(--color-light-red-900);
    --color-border-userselect-category1-light: var(--color-light-charcoal-300);
    --color-border-userselect-category1-lighter: var(--color-light-charcoal-200);
    --color-border-userselect-category1-lightest: var(--color-light-charcoal-100);
    --color-border-userselect-category1-midlight: var(--color-light-charcoal-400);
    --color-border-userselect-category2-light: var(--color-light-peach-300);
    --color-border-userselect-category2-lighter: var(--color-light-peach-200);
    --color-border-userselect-category2-lightest: var(--color-light-peach-100);
    --color-border-userselect-category2-midlight: var(--color-light-peach-400);
    --color-border-userselect-category3-light: var(--color-light-lemon-300);
    --color-border-userselect-category3-lighter: var(--color-light-lemon-200);
    --color-border-userselect-category3-lightest: var(--color-light-lemon-100);
    --color-border-userselect-category3-midlight: var(--color-light-lemon-400);
    --color-border-userselect-category4-light: var(--color-light-pink-300);
    --color-border-userselect-category4-lighter: var(--color-light-pink-200);
    --color-border-userselect-category4-lightest: var(--color-light-pink-100);
    --color-border-userselect-category4-midlight: var(--color-light-pink-400);
    --color-border-userselect-category5-light: var(--color-light-azure-300);
    --color-border-userselect-category5-lighter: var(--color-light-azure-200);
    --color-border-userselect-category5-lightest: var(--color-light-azure-100);
    --color-border-userselect-category5-midlight: var(--color-light-azure-400);
    --color-border-userselect-category6-light: var(--color-light-lila-300);
    --color-border-userselect-category6-lighter: var(--color-light-lila-200);
    --color-border-userselect-category6-lightest: var(--color-light-lila-100);
    --color-border-userselect-category6-midlight: var(--color-light-lila-400);
    --color-border-userselect-category7-light: var(--color-light-violet-300);
    --color-border-userselect-category7-lighter: var(--color-light-violet-200);
    --color-border-userselect-category7-lightest: var(--color-light-violet-100);
    --color-border-userselect-category7-midlight: var(--color-light-violet-400);
    --color-border-userselect-category8-light: var(--color-light-turquoise-300);
    --color-border-userselect-category8-lighter: var(--color-light-turquoise-200);
    --color-border-userselect-category8-lightest: var(--color-light-turquoise-100);
    --color-border-userselect-category8-midlight: var(--color-light-turquoise-400);
    --color-icon-base-interactive2-disabled-indeterminate: var(--color-light-base-inverse);
    --color-icon-base-interactive2-disabled-selected: var(--color-light-base-inverse);
    --color-icon-base-interactive2-enabled-indeterminate: var(--color-light-base-inverse);
    --color-icon-base-interactive2-enabled-selected: var(--color-light-base-inverse);
    --color-icon-base-inverse: var(--color-light-base-inverse);
    --color-icon-base-inverse-alt: var(--color-light-base-inverse);
    --color-icon-base-inverse-interactive6-hovered-error: var(--color-light-base-inverse);
    --color-icon-base-inverse-interactive6-pressed-error: var(--color-light-base-inverse);
    --color-icon-base-inverse-interactive8-active: var(--color-light-base-black);
    --color-icon-brand-enabled: var(--color-light-purple-900);
    --color-icon-brand-interactive3-active: var(--color-light-purple-900);
    --color-icon-brand-interactive3-active-alt: var(--color-light-purple-900);
    --color-icon-brand-interactive3-hovered: var(--color-light-purple-900);
    --color-icon-brand-interactive3-pressed: var(--color-light-purple-1000);
    --color-icon-brand-interactive9-hovered: var(--color-light-purple-900);
    --color-icon-brand-interactive9-pressed: var(--color-light-purple-1000);
    --color-icon-feedback-error-darkest: var(--color-light-red-900);
    --color-icon-feedback-information-darkest: var(--color-light-blue-900);
    --color-icon-feedback-interactive11-default-error: var(--color-light-red-900);
    --color-icon-feedback-interactive11-hovered-error: var(--color-light-red-900);
    --color-icon-feedback-interactive11-hovered-pressed-error: var(--color-light-red-1000);
    --color-icon-feedback-interactive6-enabled-error: var(--color-light-red-900);
    --color-icon-feedback-on-error: var(--color-light-base-inverse);
    --color-icon-feedback-on-success: var(--color-light-base-inverse);
    --color-icon-feedback-success-darkest: var(--color-light-green-900);
    --color-icon-feedback-warning-darkest: var(--color-light-orange-900);
    --color-icon-neutral-darkest: var(--color-light-grey-900);
    --color-icon-neutral-interactive10-active: var(--color-light-grey-1000);
    --color-icon-neutral-interactive10-active-default: var(--color-light-grey-1000);
    --color-icon-neutral-interactive10-active-error: var(--color-light-grey-1000);
    --color-icon-neutral-interactive10-default: var(--color-light-grey-900);
    --color-icon-neutral-interactive10-disabled: var(--color-light-grey-600);
    --color-icon-neutral-interactive10-disabled-prefilled: var(--color-light-grey-600);
    --color-icon-neutral-interactive10-error: var(--color-light-grey-1000);
    --color-icon-neutral-interactive10-filled: var(--color-light-grey-900);
    --color-icon-neutral-interactive11-active: var(--color-light-grey-1000);
    --color-icon-neutral-interactive11-default: var(--color-light-grey-900);
    --color-icon-neutral-interactive11-disabled: var(--color-light-grey-600);
    --color-icon-neutral-interactive11-error: var(--color-light-grey-1000);
    --color-icon-neutral-interactive11-filled: var(--color-light-grey-1000);
    --color-icon-neutral-interactive11-hovered: var(--color-light-grey-1000);
    --color-icon-neutral-interactive11-pressed: var(--color-light-grey-1000);
    --color-icon-neutral-interactive3-default: var(--color-light-grey-900);
    --color-icon-neutral-interactive6-active: var(--color-light-grey-1000);
    --color-icon-neutral-interactive6-disabled: var(--color-light-grey-600);
    --color-icon-neutral-interactive6-enabled: var(--color-light-grey-900);
    --color-icon-neutral-interactive6-filled: var(--color-light-grey-1000);
    --color-icon-neutral-interactive6-hovered: var(--color-light-grey-1000);
    --color-icon-neutral-interactive6-pressed: var(--color-light-grey-1000);
    --color-icon-neutral-interactive6-selected: var(--color-light-grey-1000);
    --color-icon-neutral-interactive8-active: var(--color-light-grey-1000);
    --color-icon-neutral-interactive8-default: var(--color-light-grey-900);
    --color-icon-neutral-interactive8-disabled: var(--color-light-grey-600);
    --color-icon-neutral-interactive8-hovered: var(--color-light-grey-900);
    --color-icon-neutral-interactive8-pressed: var(--color-light-grey-900);
    --color-icon-neutral-interactive8-selected: var(--color-light-grey-900);
    --color-icon-onneutral-destructive-interactive6-enabled: var(--color-light-red-900);
    --color-icon-onneutral-interactive9-disabled: var(--color-light-grey-600);
    --color-icon-onneutral-interactive9-enabled: var(--color-light-grey-900);
    --color-icon-onneutral-interactive9-hovered: var(--color-light-grey-1000);
    --color-icon-onneutral-interactive9-pressed: var(--color-light-grey-1000);
    --color-icon-onprimary-base-destructive-interactive1-disabled: var(--color-light-base-inverse);
    --color-icon-onprimary-base-destructive-interactive1-enabled: var(--color-light-base-inverse);
    --color-icon-onprimary-base-destructive-interactive1-hovered: var(--color-light-base-inverse);
    --color-icon-onprimary-base-destructive-interactive1-pressed: var(--color-light-base-inverse);
    --color-icon-onprimary-base-destructive-interactive1-progress: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive1-disabled: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive1-enabled: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive1-hovered: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive1-pressed: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive1-progress: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive6-active: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive6-disabled: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive6-enabled: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive6-hovered: var(--color-light-base-inverse);
    --color-icon-onprimary-base-interactive6-pressed: var(--color-light-base-inverse);
    --color-icon-onsecondary-brand-interactive1-disabled: var(--color-light-purple-400);
    --color-icon-onsecondary-brand-interactive1-enabled: var(--color-light-purple-900);
    --color-icon-onsecondary-brand-interactive1-hovered: var(--color-light-purple-700);
    --color-icon-onsecondary-brand-interactive1-pressed: var(--color-light-purple-1000);
    --color-icon-onsecondary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-icon-onsecondary-destructive-interactive1-disabled: var(--color-light-red-400);
    --color-icon-onsecondary-destructive-interactive1-enabled: var(--color-light-red-900);
    --color-icon-onsecondary-destructive-interactive1-hovered: var(--color-light-red-700);
    --color-icon-onsecondary-destructive-interactive1-pressed: var(--color-light-red-1000);
    --color-icon-onsecondary-destructive-interactive1-progress: var(--color-light-red-900);
    --color-icon-ontertiary-brand-interactive1-disabled: var(--color-light-purple-400);
    --color-icon-ontertiary-brand-interactive1-enabled: var(--color-light-purple-900);
    --color-icon-ontertiary-brand-interactive1-hovered: var(--color-light-purple-700);
    --color-icon-ontertiary-brand-interactive1-pressed: var(--color-light-purple-1000);
    --color-icon-ontertiary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-icon-ontertiary-destructive-interactive1-disabled: var(--color-light-red-400);
    --color-icon-ontertiary-destructive-interactive1-enabled: var(--color-light-red-900);
    --color-icon-ontertiary-destructive-interactive1-hovered: var(--color-light-red-700);
    --color-icon-ontertiary-destructive-interactive1-pressed: var(--color-light-red-1000);
    --color-icon-ontertiary-destructive-interactive1-progress: var(--color-light-red-900);
    --color-surface-base-body: var(--color-light-base-inverse);
    --color-surface-base-container: var(--color-light-base-inverse);
    --color-surface-base-container-alt: var(--color-dark-base-inverse);
    --color-surface-base-interactive10-active: var(--color-light-base-inverse);
    --color-surface-base-interactive10-active-default: var(--color-light-base-inverse);
    --color-surface-base-interactive10-default: var(--color-light-base-inverse);
    --color-surface-base-interactive10-default-alt: var(--color-light-base-inverse);
    --color-surface-base-interactive10-error: var(--color-light-base-inverse);
    --color-surface-base-interactive10-filled: var(--color-light-base-inverse);
    --color-surface-base-interactive11-active: var(--color-light-base-inverse);
    --color-surface-base-interactive11-default: var(--color-light-base-inverse);
    --color-surface-base-interactive11-default-alt: var(--color-light-base-inverse);
    --color-surface-base-interactive11-default-error: var(--color-light-base-inverse);
    --color-surface-base-interactive11-error: var(--color-light-base-inverse);
    --color-surface-base-interactive11-filled: var(--color-light-base-inverse);
    --color-surface-base-interactive11-popup-container: var(--color-light-base-inverse);
    --color-surface-base-interactive11-popup-row-default: var(--color-light-base-inverse);
    --color-surface-base-interactive3-default: var(--color-light-base-inverse);
    --color-surface-base-interactive4a-active: var(--color-light-base-inverse);
    --color-surface-base-interactive4a-default: var(--color-light-base-inverse);
    --color-surface-base-interactive4a-hovered: var(--color-light-base-inverse);
    --color-surface-base-interactive4a-pressed: var(--color-light-base-inverse);
    --color-surface-base-interactive4b-active: var(--color-light-base-inverse);
    --color-surface-base-interactive4b-default: var(--color-light-base-inverse);
    --color-surface-base-interactive4b-disabled: var(--color-light-base-inverse);
    --color-surface-base-interactive4b-hovered: var(--color-light-base-inverse);
    --color-surface-base-interactive4b-pressed: var(--color-light-base-inverse);
    --color-surface-base-interactive6-disabled: var(--color-light-base-inverse);
    --color-surface-base-interactive6-enabled: var(--color-light-base-inverse);
    --color-surface-base-interactive6-enabled-alt: var(--color-light-base-inverse);
    --color-surface-base-interactive6-enabled-error: var(--color-light-base-inverse);
    --color-surface-base-interactive7-off-disabled: var(--color-light-base-inverse);
    --color-surface-base-interactive7-off-enabled: var(--color-light-base-inverse);
    --color-surface-base-interactive7-on-disabled: var(--color-light-base-inverse);
    --color-surface-base-interactive7-on-enabled: var(--color-light-base-inverse);
    --color-surface-base-interactive8-default: var(--color-light-base-inverse);
    --color-surface-base-interactive9-disabled: var(--color-light-base-inverse);
    --color-surface-base-interactive9-enabled: var(--color-light-base-inverse);
    --color-surface-brand-darkest: var(--color-light-purple-900);
    --color-surface-brand-interactive11-popup-row-active: var(--color-light-purple-100);
    --color-surface-brand-interactive11-popup-row-selected: var(--color-light-purple-100);
    --color-surface-brand-interactive2-indeterminate: var(--color-light-purple-900);
    --color-surface-brand-interactive2-selected: var(--color-light-purple-900);
    --color-surface-brand-interactive3-active: var(--color-light-purple-100);
    --color-surface-brand-interactive3-active-alt: var(--color-light-purple-900);
    --color-surface-brand-interactive3-hovered: var(--color-light-purple-200);
    --color-surface-brand-interactive3-hovered-alt: var(--color-light-purple-200);
    --color-surface-brand-interactive3-pressed: var(--color-light-purple-300);
    --color-surface-brand-interactive4a-container-default: var(--color-light-purple-200);
    --color-surface-brand-interactive6-active: var(--color-light-purple-900);
    --color-surface-brand-interactive6-disabled: var(--color-light-purple-400);
    --color-surface-brand-interactive6-enabled: var(--color-light-purple-900);
    --color-surface-brand-interactive6-hovered: var(--color-light-purple-700);
    --color-surface-brand-interactive6-pressed: var(--color-light-purple-1000);
    --color-surface-brand-interactive7-on-enabled: var(--color-light-purple-900);
    --color-surface-brand-interactive7-on-hovered: var(--color-light-purple-800);
    --color-surface-brand-interactive8-selected: var(--color-light-purple-100);
    --color-surface-brand-interactive9-active: var(--color-light-purple-200);
    --color-surface-brand-interactive9-hovered: var(--color-light-purple-200);
    --color-surface-brand-interactive9-pressed: var(--color-light-purple-300);
    --color-surface-brand-lighter: var(--color-light-purple-200);
    --color-surface-brand-lightest: var(--color-light-purple-100);
    --color-surface-feedback-error-darkest: var(--color-light-red-900);
    --color-surface-feedback-error-lighter: var(--color-light-red-200);
    --color-surface-feedback-error-lightest: var(--color-light-red-100);
    --color-surface-feedback-information-darkest: var(--color-light-blue-900);
    --color-surface-feedback-information-lighter: var(--color-light-blue-200);
    --color-surface-feedback-information-lightest: var(--color-light-blue-100);
    --color-surface-feedback-interactive11-hovered-error: var(--color-light-red-200);
    --color-surface-feedback-interactive11-pressed-error: var(--color-light-red-300);
    --color-surface-feedback-interactive6-hovered-error: var(--color-light-red-800);
    --color-surface-feedback-interactive6-pressed-error: var(--color-light-red-1000);
    --color-surface-feedback-success-darkest: var(--color-light-green-900);
    --color-surface-feedback-success-lighter: var(--color-light-green-200);
    --color-surface-feedback-success-lightest: var(--color-light-green-100);
    --color-surface-feedback-warning-darkest: var(--color-light-orange-900);
    --color-surface-feedback-warning-lighter: var(--color-light-orange-200);
    --color-surface-feedback-warning-lightest: var(--color-light-orange-100);
    --color-surface-feedback-warning-middark: var(--color-light-orange-600);
    --color-surface-neutral-container-darkest: var(--color-light-grey-900);
    --color-surface-neutral-container-extra-darkest: var(--color-light-grey-1000);
    --color-surface-neutral-container-light: var(--color-light-grey-300);
    --color-surface-neutral-container-lighter: var(--color-light-grey-200);
    --color-surface-neutral-container-lightest: var(--color-light-grey-100);
    --color-surface-neutral-container-mid: var(--color-light-grey-500);
    --color-surface-neutral-interactive10-default-alt: var(--color-light-grey-100);
    --color-surface-neutral-interactive10-disabled: var(--color-light-grey-200);
    --color-surface-neutral-interactive10-disabled-prefilled: var(--color-light-grey-200);
    --color-surface-neutral-interactive10-filled-alt: var(--color-light-grey-200);
    --color-surface-neutral-interactive11-disabled: var(--color-light-grey-200);
    --color-surface-neutral-interactive11-popup-row-hovered: var(--color-light-grey-100);
    --color-surface-neutral-interactive11-popup-row-pressed: var(--color-light-grey-100);
    --color-surface-neutral-interactive2-disabled: var(--color-light-grey-300);
    --color-surface-neutral-interactive2-disabled-selected: var(--color-light-grey-300);
    --color-surface-neutral-interactive2-disabled-unselected: var(--color-light-grey-300);
    --color-surface-neutral-interactive2-indeterminate-disabled: var(--color-light-grey-300);
    --color-surface-neutral-interactive2-unselected: var(--color-light-grey-600);
    --color-surface-neutral-interactive6-active: var(--color-light-grey-200);
    --color-surface-neutral-interactive6-enabled: var(--color-light-grey-100);
    --color-surface-neutral-interactive6-enabled-alt: var(--color-light-grey-100);
    --color-surface-neutral-interactive6-enabled-error: var(--color-light-grey-100);
    --color-surface-neutral-interactive6-enabled-error-alt: var(--color-light-grey-100);
    --color-surface-neutral-interactive6-filled: var(--color-light-grey-100);
    --color-surface-neutral-interactive6-hovered: var(--color-light-grey-300);
    --color-surface-neutral-interactive6-pressed: var(--color-light-grey-400);
    --color-surface-neutral-interactive7-off-disabled: var(--color-light-grey-300);
    --color-surface-neutral-interactive7-off-enabled: var(--color-light-grey-900);
    --color-surface-neutral-interactive7-on-disabled: var(--color-light-grey-300);
    --color-surface-neutral-interactive8-active: var(--color-light-grey-100);
    --color-surface-neutral-interactive8-default: var(--color-light-grey-100);
    --color-surface-neutral-interactive8-default-alt: var(--color-light-grey-100);
    --color-surface-neutral-interactive8-disabled: var(--color-light-grey-100);
    --color-surface-neutral-interactive8-hovered: var(--color-light-grey-100);
    --color-surface-neutral-interactive8-pressed: var(--color-light-grey-100);
    --color-surface-neutral-interactive8-selected: var(--color-light-grey-100);
    --color-surface-neutral-interactive9-hovered: var(--color-light-grey-100);
    --color-surface-neutral-interactive9-pressed: var(--color-light-grey-300);
    --color-surface-onprimary-base-interactive1-progress: var(--color-light-base-inverse);
    --color-surface-onsecondary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-surface-ontertiary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-surface-opacity-inverse-light: var(--color-light-opacity-white-300);
    --color-surface-opacity-light: var(--color-light-opacity-black-300);
    --color-surface-opacity-lighter: var(--color-light-opacity-black-200);
    --color-surface-opacity-lightest: var(--color-light-opacity-black-100);
    --color-surface-opacity-midlight: var(--color-light-opacity-black-400);
    --color-surface-primary-brand-interactive1-disabled: var(--color-light-purple-400);
    --color-surface-primary-brand-interactive1-enabled: var(--color-light-purple-900);
    --color-surface-primary-brand-interactive1-hovered: var(--color-light-purple-800);
    --color-surface-primary-brand-interactive1-pressed: var(--color-light-purple-1000);
    --color-surface-primary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-surface-primary-destructive-interactive1-disabled: var(--color-light-red-300);
    --color-surface-primary-destructive-interactive1-enabled: var(--color-light-red-900);
    --color-surface-primary-destructive-interactive1-hovered: var(--color-light-red-700);
    --color-surface-primary-destructive-interactive1-pressed: var(--color-light-red-1000);
    --color-surface-primary-destructive-interactive1-progress: var(--color-light-red-900);
    --color-surface-secondary-base-interactive1-disabled: var(--color-light-base-inverse);
    --color-surface-secondary-base-interactive1-enabled: var(--color-light-base-inverse);
    --color-surface-secondary-base-interactive1-hovered: var(--color-light-base-inverse);
    --color-surface-secondary-base-interactive1-pressed: var(--color-light-base-inverse);
    --color-surface-secondary-base-interactive1-progress: var(--color-light-base-inverse);
    --color-surface-secondary-destructive-interactive1-disabled: var(--color-light-base-inverse);
    --color-surface-secondary-destructive-interactive1-enabled: var(--color-light-base-inverse);
    --color-surface-secondary-destructive-interactive1-hovered: var(--color-light-base-inverse);
    --color-surface-secondary-destructive-interactive1-pressed: var(--color-light-base-inverse);
    --color-surface-secondary-destructive-interactive1-progress: var(--color-light-base-inverse);
    --color-surface-tertiary-base-interactive1-disabled: var(--color-light-base-inverse);
    --color-surface-tertiary-base-interactive1-enabled: var(--color-light-base-inverse);
    --color-surface-tertiary-base-interactive1-hovered: var(--color-light-base-inverse);
    --color-surface-tertiary-base-interactive1-pressed: var(--color-light-base-inverse);
    --color-surface-tertiary-base-interactive1-progress: var(--color-light-base-inverse);
    --color-surface-tertiary-destructive-interactive1-disabled: var(--color-light-base-inverse);
    --color-surface-tertiary-destructive-interactive1-enabled: var(--color-light-base-inverse);
    --color-surface-tertiary-destructive-interactive1-hovered: var(--color-light-base-inverse);
    --color-surface-tertiary-destructive-interactive1-pressed: var(--color-light-base-inverse);
    --color-surface-tertiary-destructive-interactive1-progress: var(--color-light-base-inverse);
    --color-surface-userselect-category1-light: var(--color-light-charcoal-300);
    --color-surface-userselect-category1-lighter: var(--color-light-charcoal-200);
    --color-surface-userselect-category1-lightest: var(--color-light-charcoal-100);
    --color-surface-userselect-category1-midlight: var(--color-light-charcoal-400);
    --color-surface-userselect-category2-light: var(--color-light-peach-300);
    --color-surface-userselect-category2-lighter: var(--color-light-peach-200);
    --color-surface-userselect-category2-lightest: var(--color-light-peach-100);
    --color-surface-userselect-category2-midlight: var(--color-light-peach-400);
    --color-surface-userselect-category3-light: var(--color-light-lemon-300);
    --color-surface-userselect-category3-lighter: var(--color-light-lemon-200);
    --color-surface-userselect-category3-lightest: var(--color-light-lemon-100);
    --color-surface-userselect-category3-midlight: var(--color-light-lemon-400);
    --color-surface-userselect-category4-light: var(--color-light-pink-300);
    --color-surface-userselect-category4-lighter: var(--color-light-pink-200);
    --color-surface-userselect-category4-lightest: var(--color-light-pink-100);
    --color-surface-userselect-category4-midlight: var(--color-light-pink-400);
    --color-surface-userselect-category5-light: var(--color-light-azure-300);
    --color-surface-userselect-category5-lighter: var(--color-light-azure-200);
    --color-surface-userselect-category5-lightest: var(--color-light-azure-100);
    --color-surface-userselect-category5-midlight: var(--color-light-azure-400);
    --color-surface-userselect-category6-light: var(--color-light-lila-300);
    --color-surface-userselect-category6-lighter: var(--color-light-lila-200);
    --color-surface-userselect-category6-lightest: var(--color-light-lila-100);
    --color-surface-userselect-category6-midlight: var(--color-light-lila-400);
    --color-surface-userselect-category7-light: var(--color-light-violet-300);
    --color-surface-userselect-category7-lighter: var(--color-light-violet-200);
    --color-surface-userselect-category7-lightest: var(--color-light-violet-100);
    --color-surface-userselect-category7-midlight: var(--color-light-violet-400);
    --color-surface-userselect-category8-light: var(--color-light-turquoise-300);
    --color-surface-userselect-category8-lighter: var(--color-light-turquoise-200);
    --color-surface-userselect-category8-lightest: var(--color-light-turquoise-100);
    --color-surface-userselect-category8-midlight: var(--color-light-turquoise-400);
    --color-text-base-body: var(--color-light-base-black);
    --color-text-base-body-alt: var(--color-light-base-black);
    --color-text-base-interactive10-active: var(--color-light-base-black);
    --color-text-base-interactive10-active-default: var(--color-light-base-black);
    --color-text-base-interactive10-active-error: var(--color-light-base-black);
    --color-text-base-interactive10-active-hover: var(--color-light-base-black);
    --color-text-base-interactive10-error: var(--color-light-base-black);
    --color-text-base-interactive10-filled: var(--color-light-base-black);
    --color-text-base-interactive11-active: var(--color-light-base-black);
    --color-text-base-interactive11-error: var(--color-light-base-black);
    --color-text-base-interactive11-filled: var(--color-light-base-black);
    --color-text-base-interactive11-popup-row-active: var(--color-light-base-black);
    --color-text-base-interactive11-popup-row-default: var(--color-light-base-black);
    --color-text-base-interactive11-popup-row-hovered: var(--color-light-base-black);
    --color-text-base-interactive11-popup-row-pressed: var(--color-light-base-black);
    --color-text-base-interactive4a-active: var(--color-light-base-black);
    --color-text-base-interactive4a-default: var(--color-light-base-black);
    --color-text-base-interactive4a-hovered: var(--color-light-base-black);
    --color-text-base-interactive4a-pressed: var(--color-light-base-black);
    --color-text-base-interactive4b-active: var(--color-light-base-black);
    --color-text-base-interactive4b-hovered: var(--color-light-base-black);
    --color-text-base-interactive4b-pressed: var(--color-light-base-black);
    --color-text-base-interactive8-active: var(--color-light-base-black);
    --color-text-base-interactive8-default: var(--color-light-base-black);
    --color-text-base-interactive8-hovered: var(--color-light-base-black);
    --color-text-base-interactive8-pressed: var(--color-light-base-black);
    --color-text-base-interactive8-selected: var(--color-light-base-black);
    --color-text-base-inverse: var(--color-light-base-inverse);
    --color-text-brand-enabled: var(--color-light-purple-900);
    --color-text-brand-hovered: var(--color-light-purple-700);
    --color-text-brand-interactive3-active: var(--color-light-purple-900);
    --color-text-brand-interactive3-active-alt: var(--color-light-purple-900);
    --color-text-brand-interactive3-hovered: var(--color-light-purple-900);
    --color-text-brand-interactive3-pressed: var(--color-light-purple-1000);
    --color-text-brand-interactive4a-active: var(--color-light-purple-900);
    --color-text-brand-pressed: var(--color-light-purple-1000);
    --color-text-feedback-error-darkest: var(--color-light-red-900);
    --color-text-feedback-information-darkest: var(--color-light-blue-900);
    --color-text-feedback-interactive11-default-error: var(--color-light-red-900);
    --color-text-feedback-interactive11-hovered-error: var(--color-light-red-900);
    --color-text-feedback-interactive11-pressed-error: var(--color-light-red-1000);
    --color-text-feedback-onerror: var(--color-light-base-inverse);
    --color-text-feedback-oninformation: var(--color-light-base-inverse);
    --color-text-feedback-onsuccess: var(--color-light-base-inverse);
    --color-text-feedback-onwarning: var(--color-light-base-inverse);
    --color-text-feedback-success-darkest: var(--color-light-green-900);
    --color-text-feedback-warning-darkest: var(--color-light-orange-900);
    --color-text-link-active: var(--color-light-purple-900);
    --color-text-link-hovered: var(--color-light-purple-700);
    --color-text-link-normal: var(--color-light-purple-900);
    --color-text-link-visited: var(--color-light-purple-1000);
    --color-text-neutral-dark: var(--color-light-grey-700);
    --color-text-neutral-darkest: var(--color-light-grey-900);
    --color-text-neutral-extra-darkest: var(--color-light-grey-1000);
    --color-text-neutral-interactive10-default: var(--color-light-grey-900);
    --color-text-neutral-interactive10-disabled: var(--color-light-grey-600);
    --color-text-neutral-interactive10-disabled-prefilled: var(--color-light-grey-900);
    --color-text-neutral-interactive11-default: var(--color-light-grey-900);
    --color-text-neutral-interactive11-disabled: var(--color-light-grey-600);
    --color-text-neutral-interactive3-default: var(--color-light-grey-900);
    --color-text-neutral-interactive4a-default: var(--color-light-grey-900);
    --color-text-neutral-interactive4b-default: var(--color-light-grey-900);
    --color-text-neutral-interactive4b-disabled: var(--color-light-grey-600);
    --color-text-neutral-interactive4b-hovered: var(--color-light-grey-1000);
    --color-text-neutral-interactive8-active: var(--color-light-grey-900);
    --color-text-neutral-interactive8-default: var(--color-light-grey-900);
    --color-text-neutral-interactive8-disabled: var(--color-light-grey-600);
    --color-text-neutral-interactive8-hovered: var(--color-light-grey-900);
    --color-text-neutral-interactive8-pressed: var(--color-light-grey-900);
    --color-text-neutral-interactive8-selected: var(--color-light-grey-900);
    --color-text-neutral-interactive9-enabled: var(--color-light-grey-900);
    --color-text-neutral-middark: var(--color-light-grey-600);
    --color-text-onprimary-base-destructive-interactive1-disabled: var(--color-light-base-inverse);
    --color-text-onprimary-base-destructive-interactive1-enabled: var(--color-light-base-inverse);
    --color-text-onprimary-base-destructive-interactive1-hovered: var(--color-light-base-inverse);
    --color-text-onprimary-base-destructive-interactive1-pressed: var(--color-light-base-inverse);
    --color-text-onprimary-base-destructive-interactive1-progress: var(--color-light-base-inverse);
    --color-text-onprimary-base-interactive1-disabled: var(--color-light-base-inverse);
    --color-text-onprimary-base-interactive1-enabled: var(--color-light-base-inverse);
    --color-text-onprimary-base-interactive1-hovered: var(--color-light-base-inverse);
    --color-text-onprimary-base-interactive1-pressed: var(--color-light-base-inverse);
    --color-text-onprimary-base-interactive1-progress: var(--color-light-base-inverse);
    --color-text-onsecondary-brand-interactive1-disabled: var(--color-light-purple-400);
    --color-text-onsecondary-brand-interactive1-enabled: var(--color-light-purple-900);
    --color-text-onsecondary-brand-interactive1-hovered: var(--color-light-purple-700);
    --color-text-onsecondary-brand-interactive1-pressed: var(--color-light-purple-1000);
    --color-text-onsecondary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-text-onsecondary-destructive-interactive1-disabled: var(--color-light-red-400);
    --color-text-onsecondary-destructive-interactive1-enabled: var(--color-light-red-900);
    --color-text-onsecondary-destructive-interactive1-hovered: var(--color-light-red-700);
    --color-text-onsecondary-destructive-interactive1-pressed: var(--color-light-red-1000);
    --color-text-onsecondary-destructive-interactive1-progress: var(--color-light-red-900);
    --color-text-onsecondary-neutral-interactive1-enabled: var(--color-light-grey-900);
    --color-text-ontertiary-brand-interactive1-disabled: var(--color-light-purple-400);
    --color-text-ontertiary-brand-interactive1-enabled: var(--color-light-purple-900);
    --color-text-ontertiary-brand-interactive1-hovered: var(--color-light-purple-700);
    --color-text-ontertiary-brand-interactive1-pressed: var(--color-light-purple-1000);
    --color-text-ontertiary-brand-interactive1-progress: var(--color-light-purple-900);
    --color-text-ontertiary-destructive-interactive1-disabled: var(--color-light-red-400);
    --color-text-ontertiary-destructive-interactive1-enabled: var(--color-light-red-900);
    --color-text-ontertiary-destructive-interactive1-hovered: var(--color-light-red-700);
    --color-text-ontertiary-destructive-interactive1-pressed: var(--color-light-red-1000);
    --color-text-ontertiary-destructive-interactive1-progress: var(--color-light-red-900);
    --color-text-primary-brand-interactive2-disabled: var(--color-light-purple-500);
    --color-text-primary-brand-interactive4b-active: var(--color-light-purple-900);
    --color-text-primary-brand-interactive4b-default: var(--color-light-grey-900);
    --color-text-primary-brand-interactive4b-disabled: var(--color-light-purple-500);
    --color-text-primary-brand-interactive4b-hovered: var(--color-light-purple-700);
    --color-text-primary-brand-interactive4b-pressed: var(--color-light-purple-1000);
    --color-text-primary-interactive9-active: var(--color-light-purple-900);
    --color-text-primary-interactive9-hovered: var(--color-light-purple-900);
    --color-text-primary-interactive9-pressed: var(--color-light-purple-1000);
    --effect-background-blur: #000000;
    --elevations-blur-lg: 32px;
    --elevations-blur-md: 24px;
    --elevations-blur-sm: 16px;
    --elevations-blur-xs: 2px;
    --elevations-position-default: 0px;
    --elevations-spread-default: 0px;
    --font-family-Manrope: Manrope;
    --font-letter-spacing-none: 0px;
    --font-line-height-string: 0px;
    --font-size-2XL: 22px;
    --font-size-2XS: 9px;
    --font-size-3XL: 24px;
    --font-size-4XL: 28px;
    --font-size-5XL: 32px;
    --font-size-9XL: 48px;
    --font-size-LG: 18px;
    --font-size-MD: 16px;
    --font-size-SM: 14px;
    --font-size-XL: 20px;
    --font-size-XS: 12px;
    --font-weight-Bold: 700;
    --font-weight-Regular: 400;
    --font-weight-SemiBold: 600;
    --null-default: var(--color-light-red-500);
    --null-disabled: var(--color-light-red-100);
    --null-hover: var(--color-light-red-400);
    --null-pressed: var(--color-light-red-600);
    --size-border-width-bold: var(--size-border-bold);
    --size-border-width-none: var(--size-border-none);
    --size-border-width-normal: var(--size-border-normal);
    --size-border-width-xbold: var(--size-border-xbold);
    --size-breakpoints-desktop: 1280px;
    --size-breakpoints-mobile: 0px;
    --size-breakpoints-tablet-landscape: 1024px;
    --size-breakpoints-tablet-portait: 768px;
    --size-space-gap--1_5x: var(--size-space--1_5x);
    --size-space-gap-0_25x: var(--size-space-0_25x);
    --size-space-gap-0_5x: var(--size-space-0_5x);
    --size-space-gap-0x: var(--size-space-0x);
    --size-space-gap-10x: var(--size-space-10x);
    --size-space-gap-12x: var(--size-space-12x);
    --size-space-gap-14x: var(--size-space-14x);
    --size-space-gap-1_5x: var(--size-space-1_5x);
    --size-space-gap-1x: var(--size-space-1x);
    --size-space-gap-2x: var(--size-space-2x);
    --size-space-gap-3x: var(--size-space-3x);
    --size-space-gap-4x: var(--size-space-4x);
    --size-space-gap-5x: var(--size-space-5x);
    --size-space-gap-6x: var(--size-space-6x);
    --size-space-gap-8x: var(--size-space-8x);
    --size-space-height-1: var(--size-space-0_25x);
    --size-space-height-10: var(--size-space-2_5x);
    --size-space-height-12: var(--size-space-3x);
    --size-space-height-128: var(--size-space-32x);
    --size-space-height-16: var(--size-space-4x);
    --size-space-height-2: var(--size-space-0_5x);
    --size-space-height-20: var(--size-space-5x);
    --size-space-height-24: var(--size-space-6x);
    --size-space-height-28: var(--size-space-7x);
    --size-space-height-32: var(--size-space-8x);
    --size-space-height-36: var(--size-space-9x);
    --size-space-height-4: var(--size-space-1x);
    --size-space-height-40: var(--size-space-10x);
    --size-space-height-48: var(--size-space-12x);
    --size-space-height-5: var(--size-space-1_25x);
    --size-space-height-56: var(--size-space-14x);
    --size-space-height-6: var(--size-space-1_5x);
    --size-space-height-64: var(--size-space-16x);
    --size-space-height-72: var(--size-space-18x);
    --size-space-height-8: var(--size-space-2x);
    --size-space-height-96: var(--size-space-24x);
    --size-space-height-999: var(--size-space-pill);
    --size-space-padding-horizontal-0_25x: var(--size-space-0_25x);
    --size-space-padding-horizontal-0_5x: var(--size-space-0_5x);
    --size-space-padding-horizontal-0x: var(--size-space-0x);
    --size-space-padding-horizontal-10x: var(--size-space-10x);
    --size-space-padding-horizontal-12x: var(--size-space-12x);
    --size-space-padding-horizontal-14x: var(--size-space-14x);
    --size-space-padding-horizontal-16x: var(--size-space-16x);
    --size-space-padding-horizontal-18x: var(--size-space-18x);
    --size-space-padding-horizontal-1_5x: var(--size-space-1_5x);
    --size-space-padding-horizontal-1x: var(--size-space-1x);
    --size-space-padding-horizontal-2_5x: var(--size-space-2_5x);
    --size-space-padding-horizontal-2x: var(--size-space-2x);
    --size-space-padding-horizontal-3x: var(--size-space-3x);
    --size-space-padding-horizontal-4x: var(--size-space-4x);
    --size-space-padding-horizontal-5x: var(--size-space-5x);
    --size-space-padding-horizontal-6x: var(--size-space-6x);
    --size-space-padding-horizontal-8x: var(--size-space-8x);
    --size-space-padding-vertical-0_25x: var(--size-space-0_25x);
    --size-space-padding-vertical-0_5x: var(--size-space-0_5x);
    --size-space-padding-vertical-0x: var(--size-space-0x);
    --size-space-padding-vertical-10x: var(--size-space-10x);
    --size-space-padding-vertical-12x: var(--size-space-12x);
    --size-space-padding-vertical-14x: var(--size-space-14x);
    --size-space-padding-vertical-16x: var(--size-space-16x);
    --size-space-padding-vertical-18x: var(--size-space-18x);
    --size-space-padding-vertical-1_5x: var(--size-space-1_5x);
    --size-space-padding-vertical-1x: var(--size-space-1x);
    --size-space-padding-vertical-2_5x: var(--size-space-2_5x);
    --size-space-padding-vertical-2x: var(--size-space-2x);
    --size-space-padding-vertical-3x: var(--size-space-3x);
    --size-space-padding-vertical-4x: var(--size-space-4x);
    --size-space-padding-vertical-5x: var(--size-space-5x);
    --size-space-padding-vertical-6x: var(--size-space-6x);
    --size-space-padding-vertical-8x: var(--size-space-8x);
    --size-space-radius-0_5x: var(--size-space-0_5x);
    --size-space-radius-0x: var(--size-space-0x);
    --size-space-radius-1_25x: var(--size-space-1_25x);
    --size-space-radius-1_5x: var(--size-space-1_5x);
    --size-space-radius-1x: var(--size-space-1x);
    --size-space-radius-2_5x: var(--size-space-2_5x);
    --size-space-radius-2x: var(--size-space-2x);
    --size-space-radius-3x: var(--size-space-3x);
    --size-space-radius-4x: var(--size-space-4x);
    --size-space-radius-5x: var(--size-space-5x);
    --size-space-radius-6x: var(--size-space-6x);
    --size-space-radius-pill: var(--size-space-pill);
}

@media screen {
    :root.dark {
        /* color-scheme property is used to make sure browser UI is in light mode */
        color-scheme: dark;

        --color-border-base-container: var(--color-dark-base-black);
        --color-border-base-container-alt: var(--color-dark-grey-500);
        --color-border-base-interactive11-popup-container: var(--color-dark-grey-500);
        --color-border-brand-active: var(--color-dark-purple-900);
        --color-border-brand-hovered: var(--color-dark-purple-800);
        --color-border-brand-interactive10-active: var(--color-dark-purple-900);
        --color-border-brand-interactive10-active-default: var(--color-dark-purple-900);
        --color-border-brand-interactive11-active: var(--color-dark-purple-900);
        --color-border-brand-interactive3-light: var(--color-dark-purple-1000);
        --color-border-brand-interactive4b-active: var(--color-dark-purple-900);
        --color-border-brand-interactive4b-disabled: var(--color-dark-purple-500);
        --color-border-brand-interactive4b-hovered: var(--color-dark-purple-700);
        --color-border-brand-interactive4b-pressed: var(--color-dark-purple-1000);
        --color-border-brand-interactive9-active: var(--color-dark-purple-900);
        --color-border-brand-light: var(--color-dark-purple-700);
        --color-border-brand-pressed: var(--color-dark-purple-1000);
        --color-border-feedback-active: var(--color-dark-purple-900);
        --color-border-feedback-error-darkest: var(--color-dark-red-900);
        --color-border-feedback-error-light: var(--color-dark-red-600);
        --color-border-feedback-information-darkest: var(--color-dark-blue-900);
        --color-border-feedback-information-light: var(--color-dark-blue-600);
        --color-border-feedback-interactive10-error-darkest: var(--color-dark-red-900);
        --color-border-feedback-interactive11-error-darkest: var(--color-dark-red-900);
        --color-border-feedback-success-darkest: var(--color-dark-green-900);
        --color-border-feedback-success-light: var(--color-dark-green-600);
        --color-border-feedback-warning-darkest: var(--color-dark-orange-900);
        --color-border-feedback-warning-light: var(--color-dark-orange-600);
        --color-border-feedback-warning-middark: var(--color-dark-orange-600);
        --color-border-neutral-container-darkest: var(--color-dark-grey-900);
        --color-border-neutral-container-light: var(--color-dark-grey-600);
        --color-border-neutral-container-lighter: var(--color-dark-grey-500);
        --color-border-neutral-container-mid: var(--color-dark-grey-900);
        --color-border-neutral-container-middark: var(--color-dark-grey-600);
        --color-border-neutral-divider-mid: var(--color-dark-grey-500);
        --color-border-neutral-interactive10-default: var(--color-dark-grey-600);
        --color-border-neutral-interactive10-disabled: var(--color-dark-grey-500);
        --color-border-neutral-interactive10-disabled-prefilled: var(--color-dark-grey-500);
        --color-border-neutral-interactive10-filled: var(--color-dark-grey-600);
        --color-border-neutral-interactive11-default: var(--color-dark-grey-600);
        --color-border-neutral-interactive11-disabled: var(--color-dark-grey-500);
        --color-border-neutral-interactive11-filled: var(--color-dark-grey-700);
        --color-border-neutral-interactive2-disabled-selected: var(--color-dark-grey-900);
        --color-border-neutral-interactive2-disabled-unselected: var(--color-dark-grey-900);
        --color-border-neutral-interactive8-active: var(--color-dark-grey-500);
        --color-border-neutral-interactive8-default: var(--color-dark-grey-500);
        --color-border-neutral-interactive8-disabled: var(--color-dark-grey-500);
        --color-border-neutral-interactive8-hovered: var(--color-dark-grey-500);
        --color-border-neutral-interactive8-pressed: var(--color-dark-grey-500);
        --color-border-neutral-interactive8-selected: var(--color-dark-grey-500);
        --color-border-primary-brand-darkest: var(--color-dark-purple-900);
        --color-border-primary-brand-interactive1-disabled: var(--color-dark-purple-500);
        --color-border-primary-brand-interactive1-hovered: var(--color-dark-purple-700);
        --color-border-primary-brand-interactive1-pressed: var(--color-dark-purple-1000);
        --color-border-primary-brand-interactive2-enabled: var(--color-dark-purple-900);
        --color-border-secondary-brand-interactive1-disabled: var(--color-dark-purple-500);
        --color-border-secondary-brand-interactive1-enabled: var(--color-dark-purple-900);
        --color-border-secondary-brand-interactive1-hovered: var(--color-dark-purple-700);
        --color-border-secondary-brand-interactive1-pressed: var(--color-dark-purple-1000);
        --color-border-secondary-brand-interactive1-progress: var(--color-dark-purple-900);
        --color-border-secondary-destructive-interactive1-disabled: var(--color-dark-red-400);
        --color-border-secondary-destructive-interactive1-enabled: var(--color-dark-red-800);
        --color-border-secondary-destructive-interactive1-hovered: var(--color-dark-red-900);
        --color-border-secondary-destructive-interactive1-pressed: var(--color-dark-red-1000);
        --color-border-secondary-destructive-interactive1-progress: var(--color-dark-red-900);
        --color-border-userselect-category1-light: var(--color-dark-charcoal-900);
        --color-border-userselect-category1-lighter: var(--color-dark-charcoal-800);
        --color-border-userselect-category1-lightest: var(--color-dark-charcoal-700);
        --color-border-userselect-category1-midlight: var(--color-dark-charcoal-1000);
        --color-border-userselect-category2-light: var(--color-dark-peach-900);
        --color-border-userselect-category2-lighter: var(--color-dark-peach-800);
        --color-border-userselect-category2-lightest: var(--color-dark-peach-700);
        --color-border-userselect-category2-midlight: var(--color-dark-peach-1000);
        --color-border-userselect-category3-light: var(--color-dark-lemon-900);
        --color-border-userselect-category3-lighter: var(--color-dark-lemon-800);
        --color-border-userselect-category3-lightest: var(--color-dark-lemon-700);
        --color-border-userselect-category3-midlight: var(--color-dark-lemon-1000);
        --color-border-userselect-category4-light: var(--color-dark-pink-900);
        --color-border-userselect-category4-lighter: var(--color-dark-pink-800);
        --color-border-userselect-category4-lightest: var(--color-dark-pink-700);
        --color-border-userselect-category4-midlight: var(--color-dark-pink-1000);
        --color-border-userselect-category5-light: var(--color-dark-azure-900);
        --color-border-userselect-category5-lighter: var(--color-dark-azure-800);
        --color-border-userselect-category5-lightest: var(--color-dark-azure-700);
        --color-border-userselect-category5-midlight: var(--color-dark-azure-1000);
        --color-border-userselect-category6-light: var(--color-dark-lila-900);
        --color-border-userselect-category6-lighter: var(--color-dark-lila-800);
        --color-border-userselect-category6-lightest: var(--color-dark-lila-700);
        --color-border-userselect-category6-midlight: var(--color-dark-lila-1000);
        --color-border-userselect-category7-light: var(--color-dark-violet-900);
        --color-border-userselect-category7-lighter: var(--color-dark-violet-800);
        --color-border-userselect-category7-lightest: var(--color-dark-violet-700);
        --color-border-userselect-category7-midlight: var(--color-dark-violet-1000);
        --color-border-userselect-category8-light: var(--color-dark-turquoise-900);
        --color-border-userselect-category8-lighter: var(--color-dark-turquoise-800);
        --color-border-userselect-category8-lightest: var(--color-dark-turquoise-700);
        --color-border-userselect-category8-midlight: var(--color-dark-turquoise-1000);
        --color-icon-base-interactive2-disabled-indeterminate: var(--color-dark-base-inverse);
        --color-icon-base-interactive2-disabled-selected: var(--color-dark-base-inverse);
        --color-icon-base-interactive2-enabled-indeterminate: var(--color-dark-base-inverse);
        --color-icon-base-interactive2-enabled-selected: var(--color-dark-base-inverse);
        --color-icon-base-inverse: var(--color-dark-base-black);
        --color-icon-base-inverse-alt: var(--color-dark-grey-200);
        --color-icon-base-inverse-interactive6-hovered-error: var(--color-dark-base-black);
        --color-icon-base-inverse-interactive6-pressed-error: var(--color-dark-base-black);
        --color-icon-base-inverse-interactive8-active: var(--color-dark-base-black);
        --color-icon-brand-enabled: var(--color-dark-purple-900);
        --color-icon-brand-interactive3-active: var(--color-dark-purple-1000);
        --color-icon-brand-interactive3-active-alt: var(--color-dark-purple-1000);
        --color-icon-brand-interactive3-hovered: var(--color-dark-purple-1000);
        --color-icon-brand-interactive3-pressed: var(--color-dark-purple-1000);
        --color-icon-brand-interactive9-hovered: var(--color-dark-purple-1000);
        --color-icon-brand-interactive9-pressed: var(--color-dark-purple-1000);
        --color-icon-feedback-error-darkest: var(--color-dark-red-900);
        --color-icon-feedback-information-darkest: var(--color-dark-blue-900);
        --color-icon-feedback-interactive11-default-error: var(--color-dark-red-900);
        --color-icon-feedback-interactive11-hovered-error: var(--color-dark-base-inverse);
        --color-icon-feedback-interactive11-hovered-pressed-error: var(--color-dark-red-1000);
        --color-icon-feedback-interactive6-enabled-error: var(--color-dark-red-900);
        --color-icon-feedback-on-error: var(--color-dark-base-black);
        --color-icon-feedback-on-success: var(--color-dark-base-black);
        --color-icon-feedback-success-darkest: var(--color-dark-green-900);
        --color-icon-feedback-warning-darkest: var(--color-dark-orange-900);
        --color-icon-neutral-darkest: var(--color-dark-grey-900);
        --color-icon-neutral-interactive10-active: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive10-active-default: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive10-active-error: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive10-default: var(--color-dark-grey-900);
        --color-icon-neutral-interactive10-disabled: var(--color-dark-grey-600);
        --color-icon-neutral-interactive10-disabled-prefilled: var(--color-dark-grey-600);
        --color-icon-neutral-interactive10-error: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive10-filled: var(--color-dark-grey-900);
        --color-icon-neutral-interactive11-active: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive11-default: var(--color-dark-grey-900);
        --color-icon-neutral-interactive11-disabled: var(--color-dark-grey-600);
        --color-icon-neutral-interactive11-error: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive11-filled: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive11-hovered: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive11-pressed: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive3-default: var(--color-dark-grey-900);
        --color-icon-neutral-interactive6-active: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive6-disabled: var(--color-dark-grey-600);
        --color-icon-neutral-interactive6-enabled: var(--color-dark-grey-900);
        --color-icon-neutral-interactive6-filled: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive6-hovered: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive6-pressed: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive6-selected: var(--color-dark-grey-1000);
        --color-icon-neutral-interactive8-active: var(--color-dark-grey-900);
        --color-icon-neutral-interactive8-default: var(--color-dark-grey-900);
        --color-icon-neutral-interactive8-disabled: var(--color-dark-grey-900);
        --color-icon-neutral-interactive8-hovered: var(--color-dark-grey-900);
        --color-icon-neutral-interactive8-pressed: var(--color-dark-grey-900);
        --color-icon-neutral-interactive8-selected: var(--color-dark-grey-900);
        --color-icon-onneutral-destructive-interactive6-enabled: var(--color-dark-red-900);
        --color-icon-onneutral-interactive9-disabled: var(--color-dark-grey-600);
        --color-icon-onneutral-interactive9-enabled: var(--color-dark-grey-900);
        --color-icon-onneutral-interactive9-hovered: var(--color-dark-grey-1000);
        --color-icon-onneutral-interactive9-pressed: var(--color-dark-grey-1000);
        --color-icon-onprimary-base-destructive-interactive1-disabled: var(--color-dark-red-600);
        --color-icon-onprimary-base-destructive-interactive1-enabled: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-destructive-interactive1-hovered: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-destructive-interactive1-pressed: var(--color-dark-grey-200);
        --color-icon-onprimary-base-destructive-interactive1-progress: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-interactive1-disabled: var(--color-dark-purple-600);
        --color-icon-onprimary-base-interactive1-enabled: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-interactive1-hovered: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-interactive1-pressed: var(--color-dark-grey-200);
        --color-icon-onprimary-base-interactive1-progress: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-interactive6-active: var(--color-dark-base-black);
        --color-icon-onprimary-base-interactive6-disabled: var(--color-dark-purple-600);
        --color-icon-onprimary-base-interactive6-enabled: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-interactive6-hovered: var(--color-dark-base-inverse);
        --color-icon-onprimary-base-interactive6-pressed: var(--color-dark-grey-200);
        --color-icon-onsecondary-brand-interactive1-disabled: var(--color-dark-purple-600);
        --color-icon-onsecondary-brand-interactive1-enabled: var(--color-dark-purple-900);
        --color-icon-onsecondary-brand-interactive1-hovered: var(--color-dark-base-inverse);
        --color-icon-onsecondary-brand-interactive1-pressed: var(--color-dark-purple-200);
        --color-icon-onsecondary-brand-interactive1-progress: var(--color-dark-purple-900);
        --color-icon-onsecondary-destructive-interactive1-disabled: var(--color-dark-red-600);
        --color-icon-onsecondary-destructive-interactive1-enabled: var(--color-dark-red-800);
        --color-icon-onsecondary-destructive-interactive1-hovered: var(--color-dark-base-inverse);
        --color-icon-onsecondary-destructive-interactive1-pressed: var(--color-dark-grey-200);
        --color-icon-onsecondary-destructive-interactive1-progress: var(--color-dark-red-900);
        --color-icon-ontertiary-brand-interactive1-disabled: var(--color-dark-purple-400);
        --color-icon-ontertiary-brand-interactive1-enabled: var(--color-dark-purple-900);
        --color-icon-ontertiary-brand-interactive1-hovered: var(--color-dark-base-inverse);
        --color-icon-ontertiary-brand-interactive1-pressed: var(--color-dark-purple-1000);
        --color-icon-ontertiary-brand-interactive1-progress: var(--color-dark-purple-900);
        --color-icon-ontertiary-destructive-interactive1-disabled: var(--color-dark-red-400);
        --color-icon-ontertiary-destructive-interactive1-enabled: var(--color-dark-red-900);
        --color-icon-ontertiary-destructive-interactive1-hovered: var(--color-dark-red-700);
        --color-icon-ontertiary-destructive-interactive1-pressed: var(--color-dark-red-1000);
        --color-icon-ontertiary-destructive-interactive1-progress: var(--color-dark-red-900);
        --color-surface-base-body: var(--color-dark-grey-200);
        --color-surface-base-container: var(--color-dark-grey-300);
        --color-surface-base-container-alt: var(--color-dark-base-inverse);
        --color-surface-base-interactive10-active: var(--color-dark-grey-200);
        --color-surface-base-interactive10-active-default: var(--color-dark-grey-200);
        --color-surface-base-interactive10-default: var(--color-dark-grey-300);
        --color-surface-base-interactive10-default-alt: var(--color-dark-grey-400);
        --color-surface-base-interactive10-error: var(--color-dark-grey-200);
        --color-surface-base-interactive10-filled: var(--color-dark-grey-200);
        --color-surface-base-interactive11-active: var(--color-dark-grey-200);
        --color-surface-base-interactive11-default: var(--color-dark-grey-300);
        --color-surface-base-interactive11-default-alt: var(--color-dark-grey-300);
        --color-surface-base-interactive11-default-error: var(--color-dark-grey-300);
        --color-surface-base-interactive11-error: var(--color-dark-grey-300);
        --color-surface-base-interactive11-filled: var(--color-dark-grey-300);
        --color-surface-base-interactive11-popup-container: var(--color-dark-grey-300);
        --color-surface-base-interactive11-popup-row-default: var(--color-dark-grey-400);
        --color-surface-base-interactive3-default: var(--color-dark-grey-200);
        --color-surface-base-interactive4a-active: var(--color-dark-purple-300);
        --color-surface-base-interactive4a-default: var(--color-dark-base-black);
        --color-surface-base-interactive4a-hovered: var(--color-dark-purple-200);
        --color-surface-base-interactive4a-pressed: var(--color-dark-purple-100);
        --color-surface-base-interactive4b-active: var(--color-dark-grey-300);
        --color-surface-base-interactive4b-default: var(--color-dark-purple-300);
        --color-surface-base-interactive4b-disabled: var(--color-dark-base-black);
        --color-surface-base-interactive4b-hovered: var(--color-dark-grey-200);
        --color-surface-base-interactive4b-pressed: var(--color-dark-grey-100);
        --color-surface-base-interactive6-disabled: var(--color-dark-base-black);
        --color-surface-base-interactive6-enabled: var(--color-dark-grey-300);
        --color-surface-base-interactive6-enabled-alt: var(--color-dark-grey-400);
        --color-surface-base-interactive6-enabled-error: var(--color-dark-grey-300);
        --color-surface-base-interactive7-off-disabled: var(--color-dark-base-black);
        --color-surface-base-interactive7-off-enabled: var(--color-dark-base-black);
        --color-surface-base-interactive7-on-disabled: var(--color-dark-base-black);
        --color-surface-base-interactive7-on-enabled: var(--color-dark-base-black);
        --color-surface-base-interactive8-default: var(--color-dark-grey-300);
        --color-surface-base-interactive9-disabled: var(--color-dark-base-black);
        --color-surface-base-interactive9-enabled: var(--color-dark-base-black);
        --color-surface-brand-darkest: var(--color-dark-purple-1000);
        --color-surface-brand-interactive11-popup-row-active: var(--color-dark-purple-100);
        --color-surface-brand-interactive11-popup-row-selected: var(--color-dark-purple-100);
        --color-surface-brand-interactive2-indeterminate: var(--color-dark-purple-900);
        --color-surface-brand-interactive2-selected: var(--color-dark-purple-900);
        --color-surface-brand-interactive3-active: var(--color-dark-purple-500);
        --color-surface-brand-interactive3-active-alt: var(--color-dark-purple-900);
        --color-surface-brand-interactive3-hovered: var(--color-dark-purple-400);
        --color-surface-brand-interactive3-hovered-alt: var(--color-dark-purple-500);
        --color-surface-brand-interactive3-pressed: var(--color-dark-purple-500);
        --color-surface-brand-interactive4a-container-default: var(--color-dark-purple-500);
        --color-surface-brand-interactive6-active: var(--color-dark-purple-900);
        --color-surface-brand-interactive6-disabled: var(--color-dark-purple-500);
        --color-surface-brand-interactive6-enabled: var(--color-dark-purple-800);
        --color-surface-brand-interactive6-hovered: var(--color-dark-purple-900);
        --color-surface-brand-interactive6-pressed: var(--color-dark-purple-1000);
        --color-surface-brand-interactive7-on-enabled: var(--color-dark-purple-900);
        --color-surface-brand-interactive7-on-hovered: var(--color-dark-purple-800);
        --color-surface-brand-interactive8-selected: var(--color-dark-purple-100);
        --color-surface-brand-interactive9-active: var(--color-dark-purple-500);
        --color-surface-brand-interactive9-hovered: var(--color-dark-purple-600);
        --color-surface-brand-interactive9-pressed: var(--color-dark-purple-700);
        --color-surface-brand-lighter: var(--color-dark-purple-600);
        --color-surface-brand-lightest: var(--color-dark-purple-500);
        --color-surface-feedback-error-darkest: var(--color-dark-red-900);
        --color-surface-feedback-error-lighter: var(--color-dark-red-100);
        --color-surface-feedback-error-lightest: var(--color-dark-red-100);
        --color-surface-feedback-information-darkest: var(--color-dark-blue-900);
        --color-surface-feedback-information-lighter: var(--color-dark-blue-200);
        --color-surface-feedback-information-lightest: var(--color-dark-blue-300);
        --color-surface-feedback-interactive11-hovered-error: var(--color-dark-red-200);
        --color-surface-feedback-interactive11-pressed-error: var(--color-dark-red-300);
        --color-surface-feedback-interactive6-hovered-error: var(--color-dark-red-800);
        --color-surface-feedback-interactive6-pressed-error: var(--color-dark-red-1000);
        --color-surface-feedback-success-darkest: var(--color-dark-green-900);
        --color-surface-feedback-success-lighter: var(--color-dark-green-300);
        --color-surface-feedback-success-lightest: var(--color-dark-green-100);
        --color-surface-feedback-warning-darkest: var(--color-dark-orange-900);
        --color-surface-feedback-warning-lighter: var(--color-dark-orange-300);
        --color-surface-feedback-warning-lightest: var(--color-dark-orange-100);
        --color-surface-feedback-warning-middark: var(--color-dark-orange-600);
        --color-surface-neutral-container-darkest: var(--color-dark-grey-900);
        --color-surface-neutral-container-extra-darkest: var(--color-dark-grey-1000);
        --color-surface-neutral-container-light: var(--color-dark-grey-600);
        --color-surface-neutral-container-lighter: var(--color-dark-grey-500);
        --color-surface-neutral-container-lightest: var(--color-dark-grey-500);
        --color-surface-neutral-container-mid: var(--color-dark-grey-700);
        --color-surface-neutral-interactive10-default-alt: var(--color-dark-grey-100);
        --color-surface-neutral-interactive10-disabled: var(--color-dark-grey-400);
        --color-surface-neutral-interactive10-disabled-prefilled: var(--color-dark-grey-400);
        --color-surface-neutral-interactive10-filled-alt: var(--color-dark-grey-200);
        --color-surface-neutral-interactive11-disabled: var(--color-dark-grey-400);
        --color-surface-neutral-interactive11-popup-row-hovered: var(--color-dark-grey-500);
        --color-surface-neutral-interactive11-popup-row-pressed: var(--color-dark-grey-600);
        --color-surface-neutral-interactive2-disabled: var(--color-dark-grey-300);
        --color-surface-neutral-interactive2-disabled-selected: var(--color-dark-grey-900);
        --color-surface-neutral-interactive2-disabled-unselected: var(--color-dark-grey-900);
        --color-surface-neutral-interactive2-indeterminate-disabled: var(--color-dark-grey-900);
        --color-surface-neutral-interactive2-unselected: var(--color-dark-grey-600);
        --color-surface-neutral-interactive6-active: var(--color-dark-grey-200);
        --color-surface-neutral-interactive6-enabled: var(--color-dark-grey-300);
        --color-surface-neutral-interactive6-enabled-alt: var(--color-dark-grey-400);
        --color-surface-neutral-interactive6-enabled-error: var(--color-dark-grey-300);
        --color-surface-neutral-interactive6-enabled-error-alt: var(--color-dark-grey-400);
        --color-surface-neutral-interactive6-filled: var(--color-dark-grey-100);
        --color-surface-neutral-interactive6-hovered: var(--color-dark-grey-500);
        --color-surface-neutral-interactive6-pressed: var(--color-dark-grey-300);
        --color-surface-neutral-interactive7-off-disabled: var(--color-dark-grey-400);
        --color-surface-neutral-interactive7-off-enabled: var(--color-dark-grey-900);
        --color-surface-neutral-interactive7-on-disabled: var(--color-dark-grey-400);
        --color-surface-neutral-interactive8-active: var(--color-dark-grey-100);
        --color-surface-neutral-interactive8-default: var(--color-dark-grey-400);
        --color-surface-neutral-interactive8-default-alt: var(--color-dark-grey-100);
        --color-surface-neutral-interactive8-disabled: var(--color-dark-grey-100);
        --color-surface-neutral-interactive8-hovered: var(--color-dark-grey-400);
        --color-surface-neutral-interactive8-pressed: var(--color-dark-grey-100);
        --color-surface-neutral-interactive8-selected: var(--color-dark-grey-100);
        --color-surface-neutral-interactive9-hovered: var(--color-dark-grey-100);
        --color-surface-neutral-interactive9-pressed: var(--color-dark-grey-300);
        --color-surface-onprimary-base-interactive1-progress: var(--color-dark-base-black);
        --color-surface-onsecondary-brand-interactive1-progress: var(--color-dark-purple-900);
        --color-surface-ontertiary-brand-interactive1-progress: var(--color-dark-purple-900);
        --color-surface-opacity-inverse-light: var(--color-light-opacity-white-300);
        --color-surface-opacity-light: var(--color-light-opacity-black-300);
        --color-surface-opacity-lighter: var(--color-light-opacity-black-200);
        --color-surface-opacity-lightest: var(--color-light-opacity-black-100);
        --color-surface-opacity-midlight: var(--color-dark-opacity-dark-400);
        --color-surface-primary-brand-interactive1-disabled: var(--color-dark-purple-500);
        --color-surface-primary-brand-interactive1-enabled: var(--color-dark-purple-800);
        --color-surface-primary-brand-interactive1-hovered: var(--color-dark-purple-900);
        --color-surface-primary-brand-interactive1-pressed: var(--color-dark-purple-1000);
        --color-surface-primary-brand-interactive1-progress: var(--color-dark-purple-800);
        --color-surface-primary-destructive-interactive1-disabled: var(--color-dark-red-400);
        --color-surface-primary-destructive-interactive1-enabled: var(--color-dark-red-800);
        --color-surface-primary-destructive-interactive1-hovered: var(--color-dark-red-900);
        --color-surface-primary-destructive-interactive1-pressed: var(--color-dark-red-1000);
        --color-surface-primary-destructive-interactive1-progress: var(--color-dark-red-800);
        --color-surface-secondary-base-interactive1-disabled: var(--color-dark-purple-500);
        --color-surface-secondary-base-interactive1-enabled: var(--color-dark-grey-200);
        --color-surface-secondary-base-interactive1-hovered: var(--color-dark-purple-700);
        --color-surface-secondary-base-interactive1-pressed: var(--color-dark-purple-1000);
        --color-surface-secondary-base-interactive1-progress: var(--color-dark-grey-200);
        --color-surface-secondary-destructive-interactive1-disabled: var(--color-dark-red-400);
        --color-surface-secondary-destructive-interactive1-enabled: var(--color-dark-grey-200);
        --color-surface-secondary-destructive-interactive1-hovered: var(--color-dark-red-900);
        --color-surface-secondary-destructive-interactive1-pressed: var(--color-dark-red-1000);
        --color-surface-secondary-destructive-interactive1-progress: var(--color-dark-grey-200);
        --color-surface-tertiary-base-interactive1-disabled: var(--color-dark-base-black);
        --color-surface-tertiary-base-interactive1-enabled: var(--color-dark-base-black);
        --color-surface-tertiary-base-interactive1-hovered: var(--color-dark-base-black);
        --color-surface-tertiary-base-interactive1-pressed: var(--color-dark-base-black);
        --color-surface-tertiary-base-interactive1-progress: var(--color-dark-base-black);
        --color-surface-tertiary-destructive-interactive1-disabled: var(--color-dark-base-black);
        --color-surface-tertiary-destructive-interactive1-enabled: var(--color-dark-base-black);
        --color-surface-tertiary-destructive-interactive1-hovered: var(--color-dark-base-black);
        --color-surface-tertiary-destructive-interactive1-pressed: var(--color-dark-base-black);
        --color-surface-tertiary-destructive-interactive1-progress: var(--color-dark-base-black);
        --color-surface-userselect-category1-light: var(--color-dark-charcoal-900);
        --color-surface-userselect-category1-lighter: var(--color-dark-charcoal-800);
        --color-surface-userselect-category1-lightest: var(--color-dark-charcoal-700);
        --color-surface-userselect-category1-midlight: var(--color-dark-charcoal-1000);
        --color-surface-userselect-category2-light: var(--color-dark-peach-900);
        --color-surface-userselect-category2-lighter: var(--color-dark-peach-800);
        --color-surface-userselect-category2-lightest: var(--color-dark-peach-700);
        --color-surface-userselect-category2-midlight: var(--color-dark-peach-1000);
        --color-surface-userselect-category3-light: var(--color-dark-lemon-900);
        --color-surface-userselect-category3-lighter: var(--color-dark-lemon-800);
        --color-surface-userselect-category3-lightest: var(--color-dark-lemon-700);
        --color-surface-userselect-category3-midlight: var(--color-dark-lemon-1000);
        --color-surface-userselect-category4-light: var(--color-dark-pink-900);
        --color-surface-userselect-category4-lighter: var(--color-dark-pink-800);
        --color-surface-userselect-category4-lightest: var(--color-dark-pink-700);
        --color-surface-userselect-category4-midlight: var(--color-dark-pink-1000);
        --color-surface-userselect-category5-light: var(--color-dark-azure-900);
        --color-surface-userselect-category5-lighter: var(--color-dark-azure-800);
        --color-surface-userselect-category5-lightest: var(--color-dark-azure-700);
        --color-surface-userselect-category5-midlight: var(--color-dark-azure-1000);
        --color-surface-userselect-category6-light: var(--color-dark-lila-900);
        --color-surface-userselect-category6-lighter: var(--color-dark-lila-800);
        --color-surface-userselect-category6-lightest: var(--color-dark-lila-700);
        --color-surface-userselect-category6-midlight: var(--color-dark-lila-1000);
        --color-surface-userselect-category7-light: var(--color-dark-violet-900);
        --color-surface-userselect-category7-lighter: var(--color-dark-violet-800);
        --color-surface-userselect-category7-lightest: var(--color-dark-violet-700);
        --color-surface-userselect-category7-midlight: var(--color-dark-violet-1000);
        --color-surface-userselect-category8-light: var(--color-dark-turquoise-900);
        --color-surface-userselect-category8-lighter: var(--color-dark-turquoise-800);
        --color-surface-userselect-category8-lightest: var(--color-dark-turquoise-700);
        --color-surface-userselect-category8-midlight: var(--color-dark-turquoise-1000);
        --color-text-base-body: var(--color-dark-base-inverse);
        --color-text-base-body-alt: var(--color-dark-base-black);
        --color-text-base-interactive10-active: var(--color-dark-base-inverse);
        --color-text-base-interactive10-active-default: var(--color-dark-base-inverse);
        --color-text-base-interactive10-active-error: var(--color-dark-base-inverse);
        --color-text-base-interactive10-active-hover: var(--color-dark-base-inverse);
        --color-text-base-interactive10-error: var(--color-dark-base-inverse);
        --color-text-base-interactive10-filled: var(--color-dark-base-inverse);
        --color-text-base-interactive11-active: var(--color-dark-base-inverse);
        --color-text-base-interactive11-error: var(--color-dark-base-inverse);
        --color-text-base-interactive11-filled: var(--color-dark-base-inverse);
        --color-text-base-interactive11-popup-row-active: var(--color-dark-base-inverse);
        --color-text-base-interactive11-popup-row-default: var(--color-dark-base-inverse);
        --color-text-base-interactive11-popup-row-hovered: var(--color-dark-base-inverse);
        --color-text-base-interactive11-popup-row-pressed: var(--color-dark-base-inverse);
        --color-text-base-interactive4a-active: var(--color-dark-base-inverse);
        --color-text-base-interactive4a-default: var(--color-dark-purple-1000);
        --color-text-base-interactive4a-hovered: var(--color-dark-base-inverse);
        --color-text-base-interactive4a-pressed: var(--color-dark-base-inverse);
        --color-text-base-interactive4b-active: var(--color-dark-base-inverse);
        --color-text-base-interactive4b-hovered: var(--color-dark-base-inverse);
        --color-text-base-interactive4b-pressed: var(--color-dark-base-inverse);
        --color-text-base-interactive8-active: var(--color-dark-base-inverse);
        --color-text-base-interactive8-default: var(--color-dark-base-inverse);
        --color-text-base-interactive8-hovered: var(--color-dark-base-inverse);
        --color-text-base-interactive8-pressed: var(--color-dark-base-inverse);
        --color-text-base-interactive8-selected: var(--color-dark-base-inverse);
        --color-text-base-inverse: var(--color-dark-base-black);
        --color-text-brand-enabled: var(--color-dark-purple-1000);
        --color-text-brand-hovered: var(--color-dark-purple-700);
        --color-text-brand-interactive3-active: var(--color-dark-purple-1000);
        --color-text-brand-interactive3-active-alt: var(--color-dark-purple-1000);
        --color-text-brand-interactive3-hovered: var(--color-dark-purple-1000);
        --color-text-brand-interactive3-pressed: var(--color-dark-purple-1000);
        --color-text-brand-interactive4a-active: var(--color-dark-base-inverse);
        --color-text-brand-pressed: var(--color-dark-purple-1000);
        --color-text-feedback-error-darkest: var(--color-dark-red-900);
        --color-text-feedback-information-darkest: var(--color-dark-blue-900);
        --color-text-feedback-interactive11-default-error: var(--color-light-red-900);
        --color-text-feedback-interactive11-hovered-error: var(--color-dark-red-900);
        --color-text-feedback-interactive11-pressed-error: var(--color-dark-red-900);
        --color-text-feedback-onerror: var(--color-dark-base-black);
        --color-text-feedback-oninformation: var(--color-dark-base-black);
        --color-text-feedback-onsuccess: var(--color-dark-base-black);
        --color-text-feedback-onwarning: var(--color-dark-base-black);
        --color-text-feedback-success-darkest: var(--color-dark-green-900);
        --color-text-feedback-warning-darkest: var(--color-dark-orange-900);
        --color-text-link-active: var(--color-dark-purple-900);
        --color-text-link-hovered: var(--color-dark-purple-900);
        --color-text-link-normal: var(--color-dark-purple-1000);
        --color-text-link-visited: var(--color-dark-purple-1000);
        --color-text-neutral-dark: var(--color-dark-grey-700);
        --color-text-neutral-darkest: var(--color-dark-grey-1000);
        --color-text-neutral-extra-darkest: var(--color-dark-grey-1000);
        --color-text-neutral-interactive10-default: var(--color-dark-grey-900);
        --color-text-neutral-interactive10-disabled: var(--color-dark-grey-700);
        --color-text-neutral-interactive10-disabled-prefilled: var(--color-dark-grey-900);
        --color-text-neutral-interactive11-default: var(--color-dark-grey-900);
        --color-text-neutral-interactive11-disabled: var(--color-dark-grey-700);
        --color-text-neutral-interactive3-default: var(--color-dark-grey-900);
        --color-text-neutral-interactive4a-default: var(--color-dark-grey-900);
        --color-text-neutral-interactive4b-default: var(--color-dark-grey-900);
        --color-text-neutral-interactive4b-disabled: var(--color-dark-grey-600);
        --color-text-neutral-interactive4b-hovered: var(--color-dark-grey-1000);
        --color-text-neutral-interactive8-active: var(--color-dark-grey-300);
        --color-text-neutral-interactive8-default: var(--color-dark-grey-300);
        --color-text-neutral-interactive8-disabled: var(--color-dark-grey-300);
        --color-text-neutral-interactive8-hovered: var(--color-dark-grey-300);
        --color-text-neutral-interactive8-pressed: var(--color-dark-grey-300);
        --color-text-neutral-interactive8-selected: var(--color-dark-grey-300);
        --color-text-neutral-interactive9-enabled: var(--color-dark-grey-900);
        --color-text-neutral-middark: var(--color-dark-grey-600);
        --color-text-onprimary-base-destructive-interactive1-disabled: var(--color-dark-red-600);
        --color-text-onprimary-base-destructive-interactive1-enabled: var(--color-dark-base-inverse);
        --color-text-onprimary-base-destructive-interactive1-hovered: var(--color-dark-base-inverse);
        --color-text-onprimary-base-destructive-interactive1-pressed: var(--color-dark-grey-200);
        --color-text-onprimary-base-destructive-interactive1-progress: var(--color-dark-base-inverse);
        --color-text-onprimary-base-interactive1-disabled: var(--color-dark-purple-600);
        --color-text-onprimary-base-interactive1-enabled: var(--color-dark-base-inverse);
        --color-text-onprimary-base-interactive1-hovered: var(--color-dark-base-inverse);
        --color-text-onprimary-base-interactive1-pressed: var(--color-dark-grey-200);
        --color-text-onprimary-base-interactive1-progress: var(--color-dark-base-inverse);
        --color-text-onsecondary-brand-interactive1-disabled: var(--color-dark-purple-600);
        --color-text-onsecondary-brand-interactive1-enabled: var(--color-dark-purple-900);
        --color-text-onsecondary-brand-interactive1-hovered: var(--color-dark-base-inverse);
        --color-text-onsecondary-brand-interactive1-pressed: var(--color-dark-purple-200);
        --color-text-onsecondary-brand-interactive1-progress: var(--color-dark-purple-900);
        --color-text-onsecondary-destructive-interactive1-disabled: var(--color-dark-red-600);
        --color-text-onsecondary-destructive-interactive1-enabled: var(--color-dark-red-800);
        --color-text-onsecondary-destructive-interactive1-hovered: var(--color-dark-base-inverse);
        --color-text-onsecondary-destructive-interactive1-pressed: var(--color-dark-grey-200);
        --color-text-onsecondary-destructive-interactive1-progress: var(--color-dark-red-900);
        --color-text-onsecondary-neutral-interactive1-enabled: var(--color-dark-grey-900);
        --color-text-ontertiary-brand-interactive1-disabled: var(--color-dark-purple-400);
        --color-text-ontertiary-brand-interactive1-enabled: var(--color-dark-purple-900);
        --color-text-ontertiary-brand-interactive1-hovered: var(--color-dark-base-inverse);
        --color-text-ontertiary-brand-interactive1-pressed: var(--color-dark-purple-1000);
        --color-text-ontertiary-brand-interactive1-progress: var(--color-dark-purple-900);
        --color-text-ontertiary-destructive-interactive1-disabled: var(--color-dark-red-400);
        --color-text-ontertiary-destructive-interactive1-enabled: var(--color-dark-red-900);
        --color-text-ontertiary-destructive-interactive1-hovered: var(--color-dark-red-700);
        --color-text-ontertiary-destructive-interactive1-pressed: var(--color-dark-red-1000);
        --color-text-ontertiary-destructive-interactive1-progress: var(--color-dark-red-900);
        --color-text-primary-brand-interactive2-disabled: var(--color-dark-purple-500);
        --color-text-primary-brand-interactive4b-active: var(--color-dark-purple-900);
        --color-text-primary-brand-interactive4b-default: var(--color-dark-grey-900);
        --color-text-primary-brand-interactive4b-disabled: var(--color-dark-purple-500);
        --color-text-primary-brand-interactive4b-hovered: var(--color-dark-purple-700);
        --color-text-primary-brand-interactive4b-pressed: var(--color-dark-purple-1000);
        --color-text-primary-interactive9-active: var(--color-dark-purple-1000);
        --color-text-primary-interactive9-hovered: var(--color-dark-purple-1000);
        --color-text-primary-interactive9-pressed: var(--color-dark-purple-1000);
        --effect-background-blur: #ffffff;
        --elevations-blur-lg: 32px;
        --elevations-blur-md: 24px;
        --elevations-blur-sm: 16px;
        --elevations-blur-xs: 2px;
        --elevations-position-default: 0px;
        --elevations-spread-default: 0px;
        --font-family-Manrope: Manrope;
        --font-letter-spacing-none: 0px;
        --font-line-height-string: 0px;
        --font-size-2XL: 22px;
        --font-size-2XS: 9px;
        --font-size-3XL: 24px;
        --font-size-4XL: 28px;
        --font-size-5XL: 32px;
        --font-size-9XL: 48px;
        --font-size-LG: 18px;
        --font-size-MD: 16px;
        --font-size-SM: 14px;
        --font-size-XL: 20px;
        --font-size-XS: 12px;
        --font-weight-Bold: 700;
        --font-weight-Regular: 400;
        --font-weight-SemiBold: 600;
        --null-default: var(--color-dark-red-500);
        --null-disabled: var(--color-dark-red-100);
        --null-hover: var(--color-dark-red-400);
        --null-pressed: var(--color-dark-red-600);
        --size-border-width-bold: var(--size-border-bold);
        --size-border-width-none: var(--size-border-none);
        --size-border-width-normal: var(--size-border-normal);
        --size-border-width-xbold: var(--size-border-xbold);
        --size-breakpoints-desktop: 1280px;
        --size-breakpoints-mobile: 0px;
        --size-breakpoints-tablet-landscape: 1024px;
        --size-breakpoints-tablet-portait: 768px;
        --size-space-gap--1_5x: var(--size-space--1_5x);
        --size-space-gap-0_25x: var(--size-space-0_25x);
        --size-space-gap-0_5x: var(--size-space-0_5x);
        --size-space-gap-0x: var(--size-space-0x);
        --size-space-gap-10x: var(--size-space-10x);
        --size-space-gap-12x: var(--size-space-12x);
        --size-space-gap-14x: var(--size-space-14x);
        --size-space-gap-1_5x: var(--size-space-1_5x);
        --size-space-gap-1x: var(--size-space-1x);
        --size-space-gap-2x: var(--size-space-2x);
        --size-space-gap-3x: var(--size-space-3x);
        --size-space-gap-4x: var(--size-space-4x);
        --size-space-gap-5x: var(--size-space-5x);
        --size-space-gap-6x: var(--size-space-6x);
        --size-space-gap-8x: var(--size-space-8x);
        --size-space-height-1: var(--size-space-0_25x);
        --size-space-height-10: var(--size-space-2_5x);
        --size-space-height-12: var(--size-space-3x);
        --size-space-height-128: var(--size-space-32x);
        --size-space-height-16: var(--size-space-4x);
        --size-space-height-2: var(--size-space-0_25x);
        --size-space-height-20: var(--size-space-5x);
        --size-space-height-24: var(--size-space-6x);
        --size-space-height-28: var(--size-space-7x);
        --size-space-height-32: var(--size-space-8x);
        --size-space-height-36: var(--size-space-9x);
        --size-space-height-4: var(--size-space-1x);
        --size-space-height-40: var(--size-space-10x);
        --size-space-height-48: var(--size-space-12x);
        --size-space-height-5: var(--size-space-1_25x);
        --size-space-height-56: var(--size-space-14x);
        --size-space-height-6: var(--size-space--1_5x);
        --size-space-height-64: var(--size-space-16x);
        --size-space-height-72: var(--size-space-18x);
        --size-space-height-8: var(--size-space-2x);
        --size-space-height-96: var(--size-space-24x);
        --size-space-height-999: var(--size-space-pill);
        --size-space-padding-horizontal-0_25x: var(--size-space-0_25x);
        --size-space-padding-horizontal-0_5x: var(--size-space-0_5x);
        --size-space-padding-horizontal-0x: var(--size-space-0x);
        --size-space-padding-horizontal-10x: var(--size-space-10x);
        --size-space-padding-horizontal-12x: var(--size-space-12x);
        --size-space-padding-horizontal-14x: var(--size-space-14x);
        --size-space-padding-horizontal-16x: var(--size-space-16x);
        --size-space-padding-horizontal-18x: var(--size-space-18x);
        --size-space-padding-horizontal-1_5x: var(--size-space-1_5x);
        --size-space-padding-horizontal-1x: var(--size-space-1x);
        --size-space-padding-horizontal-2_5x: var(--size-space-2_5x);
        --size-space-padding-horizontal-2x: var(--size-space-2x);
        --size-space-padding-horizontal-3x: var(--size-space-3x);
        --size-space-padding-horizontal-4x: var(--size-space-4x);
        --size-space-padding-horizontal-5x: var(--size-space-5x);
        --size-space-padding-horizontal-6x: var(--size-space-6x);
        --size-space-padding-horizontal-8x: var(--size-space-8x);
        --size-space-padding-vertical-0_25x: var(--size-space-0_25x);
        --size-space-padding-vertical-0_5x: var(--size-space-0_5x);
        --size-space-padding-vertical-0x: var(--size-space-0x);
        --size-space-padding-vertical-10x: var(--size-space-10x);
        --size-space-padding-vertical-12x: var(--size-space-12x);
        --size-space-padding-vertical-14x: var(--size-space-14x);
        --size-space-padding-vertical-16x: var(--size-space-16x);
        --size-space-padding-vertical-18x: var(--size-space-18x);
        --size-space-padding-vertical-1_5x: var(--size-space-1_5x);
        --size-space-padding-vertical-1x: var(--size-space-1x);
        --size-space-padding-vertical-2_5x: var(--size-space-2_5x);
        --size-space-padding-vertical-2x: var(--size-space-2x);
        --size-space-padding-vertical-3x: var(--size-space-3x);
        --size-space-padding-vertical-4x: var(--size-space-4x);
        --size-space-padding-vertical-5x: var(--size-space-5x);
        --size-space-padding-vertical-6x: var(--size-space-6x);
        --size-space-padding-vertical-8x: var(--size-space-8x);
        --size-space-radius-0_5x: var(--size-space-0_5x);
        --size-space-radius-0x: var(--size-space-0x);
        --size-space-radius-1_25x: var(--size-space-1_25x);
        --size-space-radius-1_5x: var(--size-space-1_5x);
        --size-space-radius-1x: var(--size-space-1x);
        --size-space-radius-2_5x: var(--size-space-2_5x);
        --size-space-radius-2x: var(--size-space-2x);
        --size-space-radius-3x: var(--size-space-3x);
        --size-space-radius-4x: var(--size-space-4x);
        --size-space-radius-5x: var(--size-space-5x);
        --size-space-radius-6x: var(--size-space-6x);
        --size-space-radius-pill: var(--size-space-pill);
    }
}
