:root {
    /* Sizes */
    --typescale-size-xss: 0.5625rem;
    --typescale-size-xs: 0.75rem;
    --typescale-size-sm: 0.875rem;
    --typescale-size-md: 1rem;
    --typescale-size-lg: 1.125rem;
    --typescale-size-xl: 1.25rem;
    --typescale-size-2xl: 1.5rem;
    --typescale-size-3xl: 1.75rem;
    --typescale-size-4xl: 2rem;
    --typescale-size-5xl: 2.25rem;
    --typescale-size-6xl: 2.5rem;

    /* Weights */
    --typescale-weight-regular: 400;
    --typescale-weight-semibold: 600;
    --typescale-weight-bold: 700;
}

/* Display */

.typescale-display-6xl {
    font-size: var(--typescale-size-6xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 130%;
}

/* Headings */

.typescale-heading-5xl {
    font-size: var(--typescale-size-5xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 130%;
}

.typescale-heading-4xl {
    font-size: var(--typescale-size-4xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 130%;
}

.typescale-heading-3xl {
    font-size: var(--typescale-size-3xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-heading-2xl {
    font-size: var(--typescale-size-2xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-heading-xl {
    font-size: var(--typescale-size-xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-heading-lg {
    font-size: var(--typescale-size-lg);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-heading-md {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

p {
    /* Paragraph MD Regular */
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

@media only screen and (max-width: 850px) {
    .typescale-heading-5xl {
        font-size: var(--typescale-size-4xl);
    }

    .typescale-heading-4xl {
        font-size: var(--typescale-size-3xl);
    }

    .typescale-heading-3xl {
        font-size: var(--typescale-size-2xl);
    }

    .typescale-heading-2xl {
        font-size: var(--typescale-size-xl);
    }

    .typescale-heading-xl {
        font-size: var(--typescale-size-lg);
    }

    .typescale-heading-lg {
        font-size: var(--typescale-size-md);
        font-weight: var(--typescale-weight-semibold);
        line-height: 130%;
    }

    .typescale-heading-md {
        font-size: var(--typescale-size-md);
    }
}

/* Paragraphs */

.typescale-paragraph-3xl-regular {
    font-size: var(--typescale-size-3xl);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-paragraph-3xl-semibold {
    font-size: var(--typescale-size-3xl);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-paragraph-3xl-bold {
    font-size: var(--typescale-size-3xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-paragraph-2xl-regular {
    font-size: var(--typescale-size-2xl);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-paragraph-2xl-semibold {
    font-size: var(--typescale-size-2xl);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-paragraph-2xl-bold {
    font-size: var(--typescale-size-2xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-paragraph-xl-regular {
    font-size: var(--typescale-size-xl);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-paragraph-xl-semibold {
    font-size: var(--typescale-size-xl);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-paragraph-lg-regular {
    font-size: var(--typescale-size-lg);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-paragraph-lg-semibold {
    font-size: var(--typescale-size-lg);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-paragraph-lg-bold {
    font-size: var(--typescale-size-lg);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-paragraph-md-regular {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

.typescale-paragraph-md-semibold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-semibold);
    line-height: 150%;
}

.typescale-paragraph-md-bold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-bold);
    line-height: 150%;
}

.typescale-paragraph-sm-regular {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-paragraph-sm-semibold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-paragraph-sm-bold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-paragraph-xs-regular {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

.typescale-paragraph-xs-semibold {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-semibold);
    line-height: 150%;
}

.typescale-paragraph-xs-bold {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-bold);
    line-height: 150%;
}

/* Labels */

.typescale-label-3xl-regular {
    font-size: var(--typescale-size-3xl);
    font-weight: var(--typescale-weight-regular);
    line-height: 100%;
}

.typescale-label-3xl-semibold {
    font-size: var(--typescale-size-3xl);
    font-weight: var(--typescale-weight-semibold);
    line-height: 100%;
}

.typescale-label-xl-regular {
    font-size: var(--typescale-size-xl);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-label-xl-semibold {
    font-size: var(--typescale-size-xl);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-label-xl-bold {
    font-size: var(--typescale-size-xl);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-label-md-regular {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-regular);
    line-height: 130%;
}

.typescale-label-md-semibold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-semibold);
    line-height: 130%;
}

.typescale-label-md-bold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-bold);
    line-height: 130%;
}

.typescale-label-sm-regular {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-regular);
    line-height: 130%;
}

.typescale-label-sm-semibold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-semibold);
    line-height: 130%;
}

.typescale-label-xs-regular {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-regular);
    line-height: 130%;
}

.typescale-label-xs-semibold {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-semibold);
    line-height: 130%;
}

.typescale-label-xss-regular {
    font-size: var(--typescale-size-xss);
    font-weight: var(--typescale-weight-regular);
    line-height: 130%;
}

/* Button labels */

.typescale-buttonlabel-md-regular {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

.typescale-buttonlabel-md-semibold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-semibold);
    line-height: 150%;
}

.typescale-buttonlabel-md-bold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-bold);
    line-height: 150%;
}

.typescale-buttonlabel-sm-regular {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-buttonlabel-sm-semibold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-buttonlabel-sm-bold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-buttonlabel-xs-regular {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

.typescale-buttonlabel-xs-semibold {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-semibold);
    line-height: 130%;
}

.typescale-buttonlabel-xs-bold {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-bold);
    line-height: 130%;
}

/* Input labels */

.typescale-inputlabel-md-regular {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

.typescale-inputlabel-md-semibold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-semibold);
    line-height: 150%;
}

.typescale-inputlabel-sm-regular {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-inputlabel-sm-semibold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

.typescale-inputlabel-sm-bold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-inputlabel-xs-regular {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-inputlabel-xs-semibold {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-semibold);
    line-height: 140%;
}

/* Input text */

.typescale-inputtext-md-regular {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

.typescale-inputtext-md-bold {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-bold);
    line-height: 150%;
}

.typescale-inputtext-sm-regular {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-inputtext-sm-bold {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-bold);
    line-height: 140%;
}

.typescale-inputtext-xs-regular {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-regular);
    line-height: 130%;
}

.typescale-inputtext-xs-bold {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-bold);
    line-height: 130%;
}

/* Links */

.typescale-link-xl-regular {
    font-size: var(--typescale-size-xl);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-link-md-regular {
    font-size: var(--typescale-size-md);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}

.typescale-link-sm-regular {
    font-size: var(--typescale-size-sm);
    font-weight: var(--typescale-weight-regular);
    line-height: 140%;
}

.typescale-link-xs-regular {
    font-size: var(--typescale-size-xs);
    font-weight: var(--typescale-weight-regular);
    line-height: 150%;
}
