:root {
    --font-head: Manrope, sans-serif;
    --font-body: Manrope, sans-serif;

    color: var(--color-text-base-body);
}

* {
    margin: 0;
    padding: 0;
    border: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    text-decoration: none;
    font-family: var(--font-body);
    border-width: 0;
    border-style: solid;
    text-size-adjust: none;

    /* This vendor prefix is necessary as text-size-adjust is not supported in Safari */
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-text-size-adjust: none;
}

body {
    display: flex;
    flex-direction: column;
    background-color: var(--color-surface-base-body);
}

html,
body,
#root {
    block-size: 100%;
}

img {
    border-style: none !important;
}

button {
    background-color: transparent;
    font-size: inherit;
    cursor: pointer;
    color: var(--color-text-base-body);
}

table {
    border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-head);
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

a {
    color: unset;
}

address {
    font-style: normal;
}
