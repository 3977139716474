.sidebar {
    --sidebar-width: calc(240px + var(--safe-area-inset-left));
    --sidebar-inline-padding: var(--size-space-padding-horizontal-3x);
    --sidebar-block-padding: var(--size-space-padding-vertical-4x);
    --sidebar-icon-display: flex;
    --sidebar-icon-active-state-display: none;

    inline-size: var(--sidebar-width);
    block-size: 100dvh;
    position: sticky;
    inset-inline-start: 0;
    inset-block-start: 0;
    z-index: 2;
    background-color: var(--color-surface-base-body);
    border-inline-end: var(--size-border-width-normal) solid var(--color-border-neutral-container-light);
    padding-block: var(--safe-area-inset-top) var(--safe-area-inset-bottom);
    padding-inline-start: var(--safe-area-inset-left);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.sidebar__inner {
    /* We need to subtract the border-width here, otherwise the sidebar will be 1px too wide, since borders take up space in CSS  */
    padding-inline: var(--sidebar-inline-padding) calc(var(--sidebar-inline-padding) - var(--size-border-width-normal));
    padding-block: var(--sidebar-block-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-space-gap-8x);
    overflow-y: auto;
    flex: 1;
    inline-size: 100%;
}

.sidebar__collapseButton {
    --collapseButton-background-color: transparent;
    --collapseButton-icon-color: var(--color-icon-neutral-interactive3-default);

    display: flex;
    place-items: center;
    position: absolute;
    inset-block-start: calc(20px + var(--safe-area-inset-top));
    inset-inline-end: 0;
    padding-block: var(--size-space-padding-vertical-2x);
    padding-inline: var(--size-space-padding-horizontal-2x);
    border-radius: var(--size-space-radius-pill) 0 0 var(--size-space-radius-pill);
    background-color: var(--collapseButton-background-color);
}

.sidebar__collapseButton svg {
    fill: var(--collapseButton-icon-color);
}

.sidebar__collapseButton:hover {
    --collapseButton-background-color: var(--color-surface-brand-interactive3-hovered);
    --collapseButton-icon-color: var(--color-icon-brand-interactive3-hovered);
}

.sidebar__collapseButton:active {
    --collapseButton-background-color: var(--color-surface-brand-interactive3-pressed);
    --collapseButton-icon-color: var(--color-icon-brand-interactive3-pressed);
}

.sidebar__brand {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: var(--size-space-gap-2x);
}

.sidebar__logo {
    inline-size: 40px;
    padding-inline: 10px;
    block-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--size-space-radius-1_5x);
    border: 1px solid var(--color-border-neutral-container-light);
    background: var(--color-surface-base-container-alt);
}

.sidebar__schoolType {
    color: var(--color-text-neutral-darkest);
}

.sidebarList {
    display: flex;
    flex-direction: column;
    gap: var(--size-space-gap-2x);
    flex: 1;
    inline-size: 100%;
    list-style: none;
}

.sidebarList__item {
    display: flex;
    flex-direction: column;
    gap: var(--size-space-gap-1x);
}

.sidebarList__link {
    --link-background-color: var(--color-surface-base-interactive3-default);
    --link-text-color: var(--color-text-neutral-interactive3-default);
    --link-icon-color: var(--color-icon-neutral-interactive3-default);
    --link-inline-padding: var(--size-space-padding-horizontal-2x);
    --link-block-padding: var(--size-space-padding-vertical-2x);

    display: flex;
    padding-block: var(--link-inline-padding);
    padding-inline: var(--link-block-padding);
    align-items: center;
    gap: var(--size-space-gap-2x);
    border-radius: var(--size-space-radius-1_5x);
    background-color: var(--link-background-color);
    color: var(--link-text-color);
    text-align: start;
}

.sidebarList__link svg {
    fill: var(--link-icon-color);
}

.sidebarList__link p {
    flex: 1;
}

.sidebarList__link:hover {
    --link-background-color: var(--color-surface-brand-interactive3-hovered);
    --link-icon-color: var(--color-icon-brand-interactive3-hovered);
    --link-text-color: var(--color-text-brand-interactive3-hovered);

    color: var(--link-text-color);
}

.sidebarList__link:active {
    --link-background-color: var(--color-surface-brand-interactive3-pressed);
    --link-text-color: var(--color-text-brand-interactive3-pressed);
    --link-icon-color: var(--color-icon-brand-interactive3-pressed);
}

.sidebarList__link:global(.active) {
    --link-background-color: var(--color-surface-brand-interactive3-active);
    --link-text-color: var(--color-text-brand-interactive3-active);
    --link-icon-color: var(--color-icon-brand-interactive3-active);
    --sidebar-icon-display: none;
    --sidebar-icon-active-state-display: block;
}

.sidebarList__item:has(.sidebarSubList__item > a:global(.active)) .sidebarList__link {
    --link-background-color: var(--color-surface-base-interactive3-default);
    --link-text-color: var(--color-text-brand-interactive3-active);
    --link-icon-color: var(--color-icon-brand-interactive3-active);
    --sidebar-icon-display: none;
    --sidebar-icon-active-state-display: block;
}

.sidebarSubSection {
    display: none;
    gap: var(--size-space-gap-4x);
    padding-block-end: var(--size-space-padding-vertical-2x);
    padding-inline: var(--size-space-padding-horizontal-5x) var(--size-space-padding-horizontal-2x);
}

.sidebarList__item--expanded .sidebarSubSection {
    display: flex;
}

.sidebarList__item--expanded .sideBarList__chevron {
    transform: rotate(180deg);
}

.sidebarList__itemLabel {
    display: flex;
    margin-inline-end: auto;
}

.sidebarSubSection__verticalLine {
    inline-size: 2px;
    border-radius: 1px;
    background-color: var(--color-border-brand-interactive3-light);
}

.sidebarSubList {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--size-space-gap-1x);
    list-style: none;
}

.sidebarSubList__item > a {
    --sub-link-background-color: var(--color-surface-base-interactive3-default);
    --sub-link-text-color: var(--color-text-neutral-interactive3-default);

    min-block-size: 32px;
    padding-inline: var(--size-space-padding-horizontal-2_5x);
    padding-block: var(--size-space-padding-vertical-1_5x);
    background-color: var(--sub-link-background-color);
    color: var(--sub-link-text-color);
    display: flex;
    align-items: center;
    border-radius: var(--size-space-radius-1x);
}

.sidebarSubList__item > a:hover {
    --sub-link-background-color: var(--color-surface-brand-interactive3-hovered);
    --sub-link-text-color: var(--color-text-brand-interactive3-hovered);
}

.sidebarSubList__item > a:active {
    --sub-link-background-color: var(--color-surface-brand-interactive3-pressed);
    --sub-link-text-color: var(--color-text-brand-interactive3-pressed);
}

.sidebarSubList__item > a:global(.active) {
    --sub-link-background-color: var(--color-surface-brand-interactive3-active);
    --sub-link-text-color: var(--color-text-brand-interactive3-active-alt);
}

.sidebarSubList__itemLabel {
    display: flex;
    margin-inline-end: auto;
}

.sidebar--collapsed {
    --sidebar-width: calc(72px + var(--safe-area-inset-left));
    --sidebar-inline-padding: var(--size-space-padding-horizontal-4x);
}

.sidebar--collapsed .sidebar__collapseButton {
    border-radius: var(--size-space-radius-pill);
    padding-block: var(--size-space-padding-vertical-3x);
    padding-inline: var(--size-space-padding-horizontal-3x);
    position: unset;
    inset-block-start: unset;
    inset-inline-end: unset;
}

.sidebar--collapsed .sidebar__brand {
    display: none;
}

.sidebar--collapsed .sidebarList__link p {
    display: none;
}

.sidebar--collapsed .sideBarList__chevron {
    display: none;
}

.sidebar--collapsed .sidebarSubSection {
    display: none;
}

.sidebar--collapsed .sidebarList__link {
    gap: var(--size-space-gap-0x);
}

@media only screen and (pointer: coarse) {
    .sidebar--collapsed {
        --sidebar-inline-padding: var(--size-space-padding-horizontal-3x);
    }

    .sidebar__collapseButton {
        inset-block-start: calc(12px + var(--safe-area-inset-top));
    }

    .sidebar__collapseButton,
    .sidebar--collapsed .sidebar__collapseButton {
        padding-block: var(--size-space-padding-vertical-4x);
        padding-inline: var(--size-space-padding-horizontal-4x);
    }

    .sidebarList__link {
        --link-inline-padding: var(--size-space-padding-horizontal-3x);
        --link-block-padding: var(--size-space-padding-vertical-3x);
    }

    .sidebarList__item {
        gap: var(--size-space-gap-2x);
    }

    .sidebarSubSection {
        padding-inline-start: var(--size-space-padding-horizontal-6x);
    }

    .sidebarSubList {
        gap: 0;
    }

    .sidebarSubList__item > a {
        min-block-size: 48px;
    }
}

.sidebar__backToAdmin {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-neutral-darkest);
    border: var(--size-border-width-normal) solid var(--color-border-neutral-container-light);
    background: var(--color-surface-base-container-alt);
    block-size: 40px;
    gap: var(--size-space-gap-2x);
    inline-size: 100%;
    border-radius: var(--size-space-radius-2x);
    width: 100%;
}

.sidebar__backToAdmin:hover {
    --link-text-color: var(--color-text-brand-interactive3-hovered);

    color: var(--link-text-color);
}

.sidebar--collapsed .sidebar__backToAdmin {
    inline-size: 40px;
}

.sidebar__backToAdmin svg {
    color: var(--color-icon-neutral-interactive3-default);
}

.floatingSubSection {
    box-shadow: var(--elevation-xs);
}
