.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.listContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 5px;
    align-items: flex-start;
    width: 100%;
}

.listItem {
    text-align: left;
    padding: 5px 12px;
    background-color: transparent;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.listItemSelected {
    background-color: var(--color-border-brand-light);
}

.showMoreLessBtn {
    padding: 5px 12px;
    font-weight: 500;
}


.checkIcon {
    color: var(--color-border-brand-interactive10-active);
}

.title {
    font-size: 90%;
    color: grey;
}

.noMatchesMessage {
    font-style: italic;
    font-size: 90%;
    padding: 5px 12px;
    color: grey;
}
