.overlay {
    z-index: 2;
    transition: background-color 300ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay--open {
    background-color: rgb(0 0 0 / 40%);
}

.modal {
    background-color: var(--color-surface-base-container);
    scale: 0.5;
    opacity: 0;
    transition-property: scale, opacity, translate;
    transition-duration: 300ms;
    transition-timing-function: ease;
    position: fixed;
    display: flex;
    flex-direction: column;
    border-radius: var(--size-space-radius-3x);
    max-inline-size: 100%;
    max-block-size: 100%;
    overflow: hidden;
}

.modal--open {
    scale: 1;
    opacity: 1;
    translate: 0;
}

.modal__mobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    inline-size: 100%;
    padding-block: var(--size-space-padding-vertical-4x);
    padding-inline: var(--size-space-padding-horizontal-6x) var(--size-space-padding-horizontal-5x);
    gap: var(--size-space-gap-2x);
    border-block-end: 1px solid var(--color-border-neutral-container-light);
    background: var(--color-surface-base-container);
}

.modal__mobileFooter {
    inline-size: 100%;
    padding-block: var(--size-space-padding-vertical-4x) calc(var(--size-space-padding-vertical-4x) + var(--sab));
    padding-inline: var(--size-space-padding-horizontal-4x);
    background-color: var(--color-surface-base-container);
    box-shadow: var(--elevation-sm);
}

@media only screen and (max-width: 850px) {
    .modal {
        opacity: 1;
        scale: 1;
        inline-size: 100%;
        block-size: calc(100% - calc(var(--sat) + 40px));
        inset-block-end: 0;
        border-end-end-radius: 0;
        border-end-start-radius: 0;
        overflow: hidden;
        translate: 0 100%;
    }

    .modal--open {
        translate: 0;
    }
}

@media only screen and (min-width: 850px) {
    .modal__mobileHeader,
    .modal__mobileFooter {
        display: none;
    }
}
