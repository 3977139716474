.scheduleHeader {
    height: calc(100% - 35px);
}

.scheduleHeader__container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.scheduleHeaderColumn {
    border-inline: 1px solid var(--color-light-grey-200);
    height: 100%;
    flex: 1;
    overflow: hidden;
}

.scheduleHeaderColumn:last-child {
    border-inline-end: none;
}

.scheduleHeaderColumn:first-child {
    border-inline-start: none;
}

.scheduleHeaderTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid var(--color-light-grey-200);
    height: 35px;
    width: 100%;
}

.scheduleHeaderTitleText {
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-inline: 5px;
}
