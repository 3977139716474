:root {
    --ta-row-height: 55px;
    --ta-header-width: 75px;
}

.flexBox {
    display: flex;
}

.outerWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    flex-basis: 100%;
}

.wrapper--hasSelectedCourseRounds {
    flex-basis: 85%;
}

.wrapper {
    display: flex;
    width: 100%;
    --row-height: 55px;
    --header-width: 75px;
    flex: 1;
}

.innerWrapper {
    position: relative;
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    height: 100%;
}

.courseRounds {
    position: relative;
    height: 100%;
    left: var(--header-width);
    width: calc(100% - var(--header-width));
}

.hoursWrapper {
    position: relative;
    top: 0;
    left: var(--header-width);
    width: calc(100% - var(--header-width));
    height: 100%;
}

.dragLayerWrapper {
    position: absolute;
    pointer-events: none;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.positioned {
    transition: all 0.3s;
    position: absolute;
    border-top: 3px solid #ff000000;
    border-bottom: 3px solid #ff000000;
    border-left: 1px solid #ff000000;
    border-right: 1px solid #ff000000;

    top: var(--top);
    left: var(--left);
    min-width: var(--min-width);
    width: var(--width);
    height: var(--height);
}

.positioned:hover {
    z-index: 1;
    width: unset;
}

.dropbox {
    background-color: white;
    flex-basis: 15%;
    display: flex;
    z-index: 100;
    position: relative;
    transition: all 0.3s ease;
    outline: 2px dashed transparent;
    box-shadow: 0px -14px 12px -13px rgba(0, 0, 0, 0.1);
}

.dropbox--isOver {
    outline: 2px dashed var(--color-light-grey-900);
}

.dropboxContent {
    position: relative;
}

.dropbox__inner {
    width: 100%;
    padding: 10px;
    overflow-x: auto;
}

.gradientRightWrapper {
    height: 100%;
    width: 0;
}

.gradientRight {
    height: 100%;
    width: 150px;
    right: 0;
    position: absolute;
    pointer-events: none;
    z-index: 101;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.panelSlider__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-block-end: var(--size-space-padding-vertical-3x);
}

.header {
    display: flex;
    align-items: center;
    gap: var(--size-space-gap-2x);
    border-bottom: 1px solid var(--color-light-grey-200);
    justify-content: space-between;
    padding: var(--size-space-padding-horizontal-2x);
}

.filtersWrapper {
    display: flex;
    align-items: center;
    gap: var(--size-space-gap-2x);
    justify-content: space-between;
}
