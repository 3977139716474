.grid {
    --columns: 1;
    display: grid;
    grid-gap: 5px;
    padding-block-end: var(--size-space-padding-vertical-5x);
    grid-template-columns: repeat(var(--columns), 1fr);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-block: var(--size-space-padding-vertical-2x);
    padding-inline-start: var(--size-space-padding-vertical-4x);
    padding-inline-end: var(--size-space-padding-vertical-2x);
    border: 2px solid var(--color-light-grey-200);
    border-block-end: 0px;
    margin-left: 40px;
    border-top-right-radius: var(--size-space-radius-2x);
    border-top-left-radius: var(--size-space-radius-2x);
}

.header--title {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}

.addScheduleCard {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-inline-start: 40px;
    min-block-size: 400px;
}

.addScheduleTitle {
    display: flex;
    align-items: center;
    gap: var(--size-space-gap-2x);
}
