.diffCard__list {
    margin: 0;
    padding: 0 1em;

    & > li {
        font-weight: bold;

        &:not(:first-child) {
            padding-top: 0.75em;
        }

        & ul > li {
            margin-left: 1em;
            font-weight: normal;
            color: #555;
        }
    }
}
