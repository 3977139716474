.paletteButton {
    width: 2rem;
    height: 2rem;
    border-radius: 0;
}

.inUseBorder {
    border: .15rem solid #888;
}

.noBorder {
    border: none
}
