.alert {
    /* Horizontal padding of 15px on both sides */
    inline-size: min(418px, calc(100% - 30px));
    padding-inline: var(--size-space-padding-horizontal-10x);
    padding-block: var(--size-space-padding-vertical-8x)
        var(--size-space-padding-vertical-10x);
    display: flex;
    flex-direction: column;
    gap: var(--size-space-padding-vertical-10x);
}

.alert__content {
    display: flex;
    flex-direction: column;
    gap: var(--size-space-padding-vertical-6x);
}

.alert__description {
    color: var(--color-text-neutral-darkest);
}

.alert__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    gap: var(--size-space-gap-4x);
}

@media only screen and (max-width: 850px) {
    .alert {
        padding: var(--size-space-padding-horizontal-6x);
    }

    .alert__footer {
        flex-direction: column;
    }

    .alert__button {
        inline-size: 100%;
    }
}

@media (pointer: coarse) {
    .alert {
        padding: var(--size-space-padding-horizontal-6x);
    }

    .alert__footer {
        flex-direction: column;
    }

    .alert__button {
        inline-size: 100%;
    }

}