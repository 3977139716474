.viewModeOptions {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    user-select: none;
}

.viewModeOptions__button {
    width: 100%;
    background-color: transparent;
    display: flex;
    padding-block: 5px;
    border-radius: 5px;
    padding-inline: 10px;
}

.viewModeOptions__button:hover {
    background-color: var(--color-light-grey-200);
    cursor: pointer;
}

.viewModeOptions__button--active {
    background-color: var(--color-light-grey-200);
}

.columnSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block-start: 8px;
    padding-inline-start: 10px;
    width: 100%;
    border-top: 1px solid var(--color-light-grey-200);
}

.columnSelector__buttons {
    display: flex;
    align-items: center;
}

.columnSelector__buttons button:hover {
    cursor: pointer;
    background-color: var(--color-light-grey-200);
}

.viewModeOptions__text {
    padding-inline: var(--size-space-padding-horizontal-2x);
}
