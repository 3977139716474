.icon {
    /* Default icon color – TODO: Extend color support when needed */
    fill: var(--color-icon-neutral-interactive6-enabled);
    flex-shrink: 0;
}

.icon--16 {
    block-size: 16px;
    inline-size: 16px;
}

.icon--24 {
    block-size: 24px;
    inline-size: 24px;
}

.icon--32 {
    block-size: 32px;
    inline-size: 32px;
}
