.card {
    overflow: hidden;
    height: 100%;
    font-size: 0.7em;
    padding: 0.2em;
    border: 1px solid #00000044;
    border-radius: 3px;
}

.selected {
    border: 2px solid var(--color-border-feedback-active);
}

.filtered {
    opacity: 1;
}

.notFiltered {
    opacity: 0.5;
}
