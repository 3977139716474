:root {
    --z-index-base: 0;

    /* TODO: Remove these eventually, but they make the migration easier */
    --old-danger-color: #ff5656;
    --old-warning-color: #feb600;
    --old-success-color: #2ecc71;
    --old-danger-color-light: #ff56561a;
    --old-warning-color-light: #feb6001a;
    --old-success-color-light: #2ecc711a;

    /* Native insets are set by the app, this is a workaround needed for Android WebView which does not support env(safe-area-inset-*) */
    --native-inset-top: 0px;
    --native-inset-right: 0px;
    --native-inset-bottom: 0px;
    --native-inset-left: 0px;
    --safe-area-inset-top: max(env(safe-area-inset-top), var(--native-inset-top));
    --safe-area-inset-right: max(env(safe-area-inset-right), var(--native-inset-right));
    --safe-area-inset-bottom: max(env(safe-area-inset-bottom), var(--native-inset-bottom));
    --safe-area-inset-left: max(env(safe-area-inset-left), var(--native-inset-left));

    /* ELEVATIONS */

    /* These should be inside the automatically generated design-system.css file when Figma supports variables for box-shadow */
    --elevation-xs: 0px 0px 2px 0px rgb(0 0 0 / 12%);
    --elevation-sm: 0px 0px 16px 0px rgb(0 0 0 / 10%);
    --elevation-md: 0px 0px 24px 0px rgb(0 0 0 / 20%);
    --elevation-lg: 0px 0px 32px 0px rgb(0 0 0 / 30%);

    /* TYPESCALE */

    /* These should be inside the automatically generated typography.css file when Figma supports variables for line-height */
    --font-line-height-100: 100%;
    --font-line-height-130: 130%;
    --font-line-height-140: 140%;
    --font-line-height-150: 150%;
}
