:root {
    --schedule-height: 895px;
    --timeline-width: 40px;
    --day-header-height: 35px;
    --full-day-header-height: 71px;
    --allocated-percent: 80;
    --schedule-content-zindex: 2;
}

.wrapper {
    --columns: 1;
    --height-multiplier: 0.6;

    position: relative;
    height: var(--schedule-height);
}

.wrapper--multipleSchedules {
    height: calc(var(--schedule-height) / (var(--columns) * var(--height-multiplier)));
}

.day {
    position: absolute;
    top: 0px;
    height: 100%;
    border: 2px solid var(--color-light-grey-200);
    container: dayItem / inline-size;
    overflow: hidden;
}

.day:first-child {
    border-top-left-radius: var(--size-space-radius-2x);
    border-bottom-left-radius: var(--size-space-radius-2x);
}
.day:last-child {
    border-top-right-radius: var(--size-space-radius-2x);
    border-bottom-right-radius: var(--size-space-radius-2x);
}

.day:nth-child(even) {
    background-color: transparent;
    border-inline: 0px;
}

.day--perSchedule:first-child {
    border-top-left-radius: 0px;
}

.day--perSchedule:last-child {
    border-top-right-radius: 0px;
}

.day--moreThanOneColumn:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.02);
}

.dayHeaderText {
    display: block;
    font-weight: var(--typescale-weight-semibold);
}

.dayHeaderText--short {
    display: none;
}

.daysWrapper {
    position: absolute;
    top: 0;
    left: var(--timeline-width);
    width: calc(100% - var(--timeline-width));
    background-color: white;

    z-index: 2;
}

.daysWrapper--perSchedule {
    height: calc(var(--allocated-percent) * 1% + var(--day-header-height) * (100 - var(--allocated-percent)) / 100);
}

.daysWrapper--default {
    height: calc(
        var(--allocated-percent) * 1% + var(--full-day-header-height) * (100 - var(--allocated-percent)) / 100
    );
}

.dayHeader {
    width: 100%;
    height: var(--day-header-height);
    text-align: center;
    font-weight: bold;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-block-end: 2px solid var(--color-light-grey-200);
}

.timeLineWrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: var(--schedule-content-zindex);
}

.timeLineWrapper--perSchedule {
    top: var(--day-header-height);
    height: calc(100% - var(--day-header-height));
}

.timeLineWrapper--default {
    top: var(--full-day-header-height);
    height: calc(100% - var(--full-day-header-height));
}

.lectures {
    position: absolute;
    left: var(--timeline-width);
    width: calc(100% - var(--timeline-width));
    z-index: var(--schedule-content-zindex);
}

.lectures--perSchedule {
    top: var(--day-header-height);
    height: calc(100% - var(--day-header-height));
}

.lectures--default {
    top: var(--full-day-header-height);
    height: calc(100% - var(--full-day-header-height));
}

.dragLayerWrapper {
    position: absolute;
    pointer-events: none;
    z-index: 100;
    left: var(--timeline-width);
    width: calc(100% - var(--timeline-width));
}

.dragLayerWrapper--perSchedule {
    top: var(--day-header-height);
    height: calc(100% - var(--day-header-height));
}

.dragLayerWrapper--default {
    top: var(--full-day-header-height);
    height: calc(100% - var(--full-day-header-height));
}

.createBlockLayerWrapper {
    position: absolute;
    z-index: 100;
    left: var(--timeline-width);
    width: calc(100% - var(--timeline-width));
}

.createBlockLayerWrapper--perSchedule {
    top: var(--day-header-height);
    height: calc(100% - var(--day-header-height));
}

.createBlockLayerWrapper--default {
    top: var(--full-day-header-height);
    height: calc(100% - var(--full-day-header-height));
}

.unallocatedLecturesBackground {
    pointer-events: none;
    position: absolute;
    top: calc(var(--allocated-percent) * 1%);
    left: var(--timeline-width);
    width: calc(100% - var(--timeline-width));
    height: calc(100% - var(--allocated-percent) * 1%);
}
.whiteBackdrop {
    position: absolute;
    width: 100%;

    background: white;
    z-index: 1;
}

.whiteBackdrop--perSchedule {
    height: calc(var(--allocated-percent) * 1% + var(--day-header-height) * (100 - var(--allocated-percent)) / 100);
}

.whiteBackdrop--default {
    height: calc(
        var(--allocated-percent) * 1% + var(--full-day-header-height) * (100 - var(--allocated-percent)) / 100
    );
}

@container dayItem (max-width: 80px) {
    .dayHeaderText {
        display: none;
    }

    .dayHeaderText--short {
        display: block;
    }
}
