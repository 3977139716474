.dropdown--size-sm {
    --dropdown-padding-inline: var(--size-space-padding-horizontal-1x);
    --dropdown-padding-block: var(--size-space-padding-vertical-1x);
}

.dropdown--size-md {
    --dropdown-padding-inline: var(--size-space-padding-horizontal-2x);
    --dropdown-padding-block: var(--size-space-padding-vertical-2x);
}

.dropdown--size-lg {
    --dropdown-padding-inline: var(--size-space-padding-horizontal-3x);
    --dropdown-padding-block: var(--size-space-padding-vertical-3x);
}

.dropdown {
    display: flex;
    flex-direction: column;
    padding-block: var(--dropdown-padding-block);
    padding-inline: var(--dropdown-padding-inline);
    gap: var(--size-space-gap-1x);
    border-radius: var(--size-space-radius-2x);
    background: var(--color-surface-base-interactive11-popup-container);
    box-shadow: var(--elevation-sm);
}

.dropdown__item--size-sm {
    --dropdownItem-padding-block: var(--size-space-padding-vertical-1x);
    --dropdownItem-padding-inline: var(--size-space-padding-horizontal-2x);
    --dropdownItem-gap: var(--size-space-gap-1_5x);
    --dropdownItem-font-size: var(--typescale-size-sm);
    --dropdownItem-min-height: 32px;
}

.dropdown__item--size-md {
    --dropdownItem-padding-block: var(--size-space-padding-vertical-2x);
    --dropdownItem-padding-inline: var(--size-space-padding-horizontal-3x);
    --dropdownItem-gap: var(--size-space-gap-2x);
    --dropdownItem-font-size: var(--typescale-size-sm);
    --dropdownItem-min-height: 40px;
}

.dropdown__item--size-lg {
    --dropdownItem-padding-block: var(--size-space-padding-vertical-3x);
    --dropdownItem-padding-inline: var(--size-space-padding-horizontal-4x);
    --dropdownItem-gap: var(--size-space-gap-2x);
    --dropdownItem-font-size: var(--typescale-size-md);
    --dropdownItem-min-height: 48px;
}

.dropdown__item {
    --dropdownItem-background-color: var(
        --color-surface-base-interactive11-popup-row-default
    );
    --dropdownItem-text-color: var(
        --color-text-base-interactive11-popup-row-default
    );
    --dropdownItem-icon-color: var(--color-icon-neutral-interactive11-default);

    display: flex;
    align-items: center;
    gap: var(--dropdownItem-gap);
    padding-block: var(--dropdownItem-padding-block);
    padding-inline: var(--dropdownItem-padding-inline);
    border-radius: var(--size-space-radius-2x);
    background: var(--dropdownItem-background-color);
    font-size: var(--dropdownItem-font-size);
    color: var(--dropdownItem-text-color);
    min-block-size: var(--dropdownItem-min-height);
    text-align: start;
}

.dropdownItem__endDecorator {
    display: flex;
    margin-inline-start: auto;
}

.dropdown__item > svg {
    fill: var(--dropdownItem-icon-color);
}

.dropdown__item:hover,
.dropdown__item--focused {
    --dropdownItem-background-color: var(
        --color-surface-neutral-interactive11-popup-row-hovered
    );
    --dropdownItem-text-color: var(
        --color-text-base-interactive11-popup-row-hovered
    );
    --dropdownItem-icon-color: var(--color-icon-neutral-interactive11-hovered);
}

.dropdown__item:active {
    --dropdownItem-background-color: var(
        --color-surface-neutral-interactive11-popup-row-pressed
    );
    --dropdownItem-text-color: var(
        --color-text-base-interactive11-popup-row-pressed
    );
    --dropdownItem-icon-color: var(--color-icon-neutral-interactive11-pressed);
}

.dropdown__item--selected {
    --dropdownItem-background-color: var(
        --color-surface-brand-interactive11-popup-row-selected
    );
}

.dropdown__item--destructive {
    --dropdownItem-background-color: var(
        --color-surface-base-interactive11-popup-row-default
    );
    --dropdownItem-text-color: var(--color-text-feedback-error-darkest);
    --dropdownItem-icon-color: var(--color-icon-feedback-error-darkest);
}

.dropdown__item--destructive.dropdown__item:hover,
.dropdown__item--destructive.dropdown__item--focused {
    --dropdownItem-background-color: var(
        --color-surface-neutral-interactive11-popup-row-hovered
    );
    --dropdownItem-text-color: var(--color-text-feedback-error-darkest);
    --dropdownItem-icon-color: var(--color-icon-feedback-error-darkest);
}

@media only screen and (pointer: coarse) {
    /* Change size to LG */
    .dropdown {
        --dropdown-padding-inline: var(--size-space-padding-horizontal-3x);
        --dropdown-padding-block: var(--size-space-padding-vertical-3x);
    }

    .dropdown__item {
        --dropdownItem-padding-block: var(--size-space-padding-vertical-3x);
        --dropdownItem-padding-inline: var(--size-space-padding-horizontal-4x);
        --dropdownItem-gap: var(--size-space-gap-2x);
        --dropdownItem-font-size: var(--typescale-size-md);
    }
}
