.devInfo {
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow: hidden;
}

.devInfoRow {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
