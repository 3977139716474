.button {
    --button-color: var(--button-default-color);
    --button-text-color: var(--button-default-text-color);
    block-size: var(--button-size);
    inline-size: var(--button-size);
    background-color: var(--button-color);
    border-radius: var(--button-radius);
    font-size: var(--button-font-size);
    color: var(--button-text-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
}

.button svg {
    fill: var(--button-text-color);
}

.button:hover {
    --button-color: var(--button-hover-color);
    --button-text-color: var(--button-hover-text-color);
    cursor: pointer;
}

.button:active {
    --button-color: var(--button-active-color);
    --button-text-color: var(--button-active-text-color);
}

.button:disabled {
    --button-color: var(--button-disabled-color);
    --button-text-color: var(--button-disabled-text-color);
    cursor: default;
}

.button:hover:disabled {
    cursor: default;
    background-color: transparent;
}

.button--size-xs {
    --button-size: 24px;
    --button-font-size: 14px;
    --button-radius: var(--size-space-radius-1x);
}

.button--size-sm {
    --button-size: 32px;
    --button-font-size: 20px;
    --button-radius: var(--size-space-radius-1x);
}

.button--size-md {
    --button-size: 40px;
    --button-font-size: 20px;
    --button-radius: var(--size-space-radius-1_5x);
}

.button--size-lg {
    --button-size: 48px;
    --button-font-size: 20px;
    --button-radius: var(--size-space-radius-2x);
}

.button--variant-primary {
    --button-default-color: var(--color-surface-brand-interactive6-enabled);
    --button-hover-color: var(--color-surface-brand-interactive6-hovered);
    --button-active-color: var(--color-surface-brand-interactive6-pressed);
    --button-disabled-color: var(--color-surface-primary-brand-interactive6-disabled);

    --button-default-text-color: var(--color-icon-onprimary-base-interactive6-enabled);
    --button-hover-text-color: var(--color-icon-onprimary-base-interactive6-hovered);
    --button-active-text-color: var(--color-icon-onprimary-base-interactive6-pressed);
    --button-disabled-text-color: var(--color-icon-onprimary-base-interactive6-disabled);
}

.button--variant-secondary {
    --button-default-color: var(--color-surface-neutral-interactive6-enabled);
    --button-hover-color: var(--color-surface-neutral-interactive6-hovered);
    --button-active-color: var(--color-surface-neutral-interactive6-pressed);
    --button-disabled-color: var(--color-surface-neutral-interactive6-disabled);

    --button-default-text-color: var(--color-icon-neutral-interactive6-enabled);
    --button-hover-text-color: var(--color-icon-neutral-interactive6-hovered);
    --button-active-text-color: var(--color-icon-neutral-interactive6-pressed);
    --button-disabled-text-color: var(--color-icon-neutral-interactive6-disabled);
}

.button--variant-tertiary {
    --button-default-color: transparent;
    --button-hover-color: var(--color-surface-neutral-interactive6-hovered);
    --button-active-color: var(--color-surface-neutral-interactive6-pressed);
    --button-disabled-color: var(transparent);

    --button-default-text-color: var(--color-icon-neutral-interactive6-enabled);
    --button-hover-text-color: var(--color-icon-neutral-interactive6-hovered);
    --button-active-text-color: var(--color-icon-neutral-interactive6-pressed);
    --button-disabled-text-color: var(--color-icon-neutral-interactive6-disabled);
}

.button--pill {
    --button-radius: var(--size-space-radius-pill);
}
