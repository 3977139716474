.wrapper {
    position: sticky;
    top: 0;
    z-index: 11;
    background-color: white;
    border-bottom: 1px solid #ccc;
}

.container {
    width: calc(100% - var(--ta-header-width));
    height: var(--ta-row-height);
    background-color: white;
    position: relative;
    top: 0;
    z-index: 5;
    left: var(--ta-header-width);
}

.interval {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.label {
    top: 10px;
    font-size: 12px;
    position: absolute;
}
.line {
    height: 30px;
    width: 1px;
    background-color: #ccc;
    margin-top: 30px;
}
