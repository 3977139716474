.button--size-lg {
    --button-height: 48px;
    --button-padding-block: var(--size-space-padding-vertical-3x);
    --button-padding-inline: var(--size-space-padding-horizontal-5x);
    --button-border-radius: var(--size-space-radius-2x);
    --button-text-size: 16px;
    --button-font-weight: 700;
    --button-gap: var(--size-space-gap-1_5x);
}

.button--size-md {
    --button-height: 40px;
    --button-padding-block: var(--size-space-padding-vertical-2x);
    --button-padding-inline: var(--size-space-padding-horizontal-4x);
    --button-border-radius: var(--size-space-radius-2x);
    --button-text-size: 14px;
    --button-font-weight: 700;
    --button-gap: var(--size-space-gap-1x);
}

.button--size-sm {
    --button-height: 32px;
    --button-padding-block: var(--size-space-padding-vertical-2x);
    --button-padding-inline: var(--size-space-padding-horizontal-3x);
    --button-border-radius: var(--size-space-radius-1_5x);
    --button-text-size: 12px;
    --button-font-weight: 600;
    --button-gap: var(--size-space-gap-1x);
}

.button--size-xs {
    --button-height: 24px;
    --button-padding-block: var(--size-space-padding-vertical-0_5x);
    --button-padding-inline: 0;
    --button-border-radius: 0;
    --button-text-size: 12px;
    --button-font-weight: 400;
    --button-gap: var(--size-space-gap-0_5x);
}

.button--variant-primary {
    --button-fill-default: var(--color-surface-primary-brand-interactive1-enabled);
    --button-fill-hovered: var(--color-surface-primary-brand-interactive1-hovered);
    --button-fill-pressed: var(--color-surface-primary-brand-interactive1-pressed);
    --button-fill-disabled: var(--color-surface-primary-brand-interactive1-disabled);
    --button-fill-progress: var(--color-surface-primary-brand-interactive1-progress);
    --button-fill: var(--button-fill-default);
    --button-text-color: var(--color-text-onprimary-base-interactive1-enabled);
    background-color: var(--button-fill);
    border: 1px solid transparent;
}

.button--variant-primary.button--destructive {
    --button-fill-default: var(--color-surface-primary-destructive-interactive1-enabled);
    --button-fill-hovered: var(--color-surface-primary-destructive-interactive1-hovered);
    --button-fill-pressed: var(--color-surface-primary-destructive-interactive1-pressed);
    --button-fill-disabled: var(--color-surface-primary-destructive-interactive1-disabled);
    --button-fill-progress: var(--color-surface-primary-destructive-interactive1-progress);
}

.button--variant-primary:hover {
    cursor: pointer;
    --button-fill: var(--button-fill-hovered);
    --button-text-color: var(--color-text-onprimary-base-interactive1-hovered);
}

.button--variant-primary:active {
    --button-fill: var(--button-fill-pressed);
    --button-text-color: var(--color-text-onprimary-base-interactive1-pressed);
}

.button--variant-primary.button--disabled {
    --button-fill: var(--button-fill-disabled);
    --button-text-color: var(--color-text-onprimary-base-interactive1-disabled);
}

.button--variant-primary.button--loading {
    --button-fill: var(--button-fill-progress);
    --button-text-color: var(--color-text-onprimary-base-interactive1-progress);
}

.button--variant-secondary {
    --button-border-color-default: var(--color-border-secondary-brand-interactive1-enabled);
    --button-border-color-hovered: var(--color-border-secondary-brand-interactive1-hovered);
    --button-border-color-pressed: var(--color-border-secondary-brand-interactive1-pressed);
    --button-border-color-disabled: var(--color-border-secondary-brand-interactive1-disabled);
    --button-border-color-progress: var(--color-border-secondary-brand-interactive1-progress);
    --button-text-color-default: var(--color-text-onsecondary-brand-interactive1-enabled);
    --button-text-color-hovered: var(--color-text-onsecondary-brand-interactive1-hovered);
    --button-text-color-pressed: var(--color-text-onsecondary-brand-interactive1-pressed);
    --button-text-color-disabled: var(--color-text-onsecondary-brand-interactive1-disabled);
    --button-text-color-progress: var(--color-text-onsecondary-brand-interactive1-progress);
    --button-border-color: var(--button-border-color-default);
    --button-text-color: var(--button-text-color-default);
    border: 1px solid var(--button-border-color);
    background-color: transparent;
}

.button--variant-secondary.button--destructive {
    --button-border-color-default: var(--color-border-secondary-destructive-interactive1-enabled);
    --button-border-color-hovered: var(--color-border-secondary-destructive-interactive1-hovered);
    --button-border-color-pressed: var(--color-border-secondary-destructive-interactive1-pressed);
    --button-border-color-disabled: var(--color-border-secondary-destructive-interactive1-disabled);
    --button-border-color-progress: var(--color-border-secondary-destructive-interactive1-progress);
    --button-text-color-default: var(--color-text-onsecondary-destructive-interactive1-enabled);
    --button-text-color-hovered: var(--color-text-onsecondary-destructive-interactive1-hovered);
    --button-text-color-pressed: var(--color-text-onsecondary-destructive-interactive1-pressed);
    --button-text-color-disabled: var(--color-text-onsecondary-destructive-interactive1-disabled);
    --button-text-color-progress: var(--color-text-onsecondary-destructive-interactive1-progress);
}

.button--variant-secondary:hover {
    cursor: pointer;
    --button-border-color: var(--button-border-color-hovered);
    --button-text-color: var(--button-text-color-hovered);
}

.button--variant-secondary:active {
    --button-border-color: var(--button-border-color-pressed);
    --button-text-color: var(--button-text-color-pressed);
}

.button--variant-secondary.button--disabled {
    --button-border-color: var(--button-border-color-disabled);
    --button-text-color: var(--button-text-color-disabled);
}

.button--variant-secondary.button--loading {
    --button-border-color: var(--button-border-color-progress);
    --button-text-color: var(--button-text-color-progress);
}

.button--variant-tertiary {
    --button-text-color-default: var(--color-text-ontertiary-brand-interactive1-enabled);
    --button-text-color-hovered: var(--color-text-ontertiary-brand-interactive1-hovered);
    --button-text-color-pressed: var(--color-text-ontertiary-brand-interactive1-pressed);
    --button-text-color-disabled: var(--color-text-ontertiary-brand-interactive1-disabled);
    --button-text-color-progress: var(--color-text-ontertiary-brand-interactive1-progress);
    --button-text-color: var(--button-text-color-default);
    --button-padding-inline: 0 !important;
}

.button--variant-tertiary.button--destructive {
    --button-text-color-default: var(--color-text-ontertiary-destructive-interactive1-enabled);
    --button-text-color-hovered: var(--color-text-ontertiary-destructive-interactive1-hovered);
    --button-text-color-pressed: var(--color-text-ontertiary-destructive-interactive1-pressed);
    --button-text-color-disabled: var(--color-text-ontertiary-destructive-interactive1-disabled);
    --button-text-color-progress: var(--color-text-ontertiary-destructive-interactive1-progress);
}

.button--variant-tertiary:hover {
    --button-text-color: var(--button-text-color-hovered);
}

.button--variant-tertiary:active {
    --button-text-color: var(--button-text-color-pressed);
}

.button--variant-tertiary.button--disabled {
    --button-text-color: var(--button-text-color-disabled);
}

.button--variant-tertiary.button--loading {
    --button-text-color: var(--button-text-color-progress);
}

.button {
    block-size: var(--button-height);
    padding-block: var(--button-padding-block);
    padding-inline: var(--button-padding-inline);
    border-radius: var(--button-border-radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--button-gap);
    color: var(--button-text-color);
    font-size: var(--button-text-size);
    font-weight: var(--button-font-weight);
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
}

.button svg {
    fill: var(--button-text-color);
}

.button.button--fullWidth {
    inline-size: 100%;
}

.button:disabled {
    cursor: default;
}

@media (pointer: coarse) {
    .button {
        --button-height: 48px;
        --button-padding-block: var(--size-space-padding-vertical-3x);
        --button-padding-inline: var(--size-space-padding-horizontal-5x);
        --button-border-radius: var(--size-space-radius-2x);
        --button-text-size: 16px;
        --button-font-weight: 700;
        --button-gap: var(--size-space-gap-1_5x);
    }
}
